import authHeader from "../auth-header";
import config from "./config";
import { apiMessages } from "../constant/messages";
import { status } from "api/constant/shipmentStatus";
const API_URL = config.basename + "Shipments/";

/*--- */
//direct'in eklenmesi
const addShipmentDirect = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },

    body: JSON.stringify({
      receiverId: data.receiverId,
      receiverType: data.receiverType,
      gatheringPlaceId: data.gatheringPlaceId,
      gatheringPlaceType: data.gatheringPlaceType,

      productObject: data.productObject,
      deliveryRangeId: data.deliveryRangeId,
      distributionWarehouseId: data.distributionWarehouseId,
      deliveryCatalogId: data.deliveryCatalogId,

      //ProductOutlet: data.ProductOutlet,
      SalesChannel: data.SalesChannel,
      deliveryCost: 10,
      orderNumber: data.orderNumber,
      dateOfSale: data.dateOfSale,
      dateOfDispatch: data.dateOfDispatch,
      note: data.note,

      /* outletPlaceType: data.outletPlaceType,
      outletPlaceId: data.outletPlaceId, */
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "addDirectShipment",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.create,
    };
  }
};

/*--- */
//parçalının eklenmesi
const addShipmentPartial = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },

    body: JSON.stringify({
      gatheringPlaceId: data.gatheringPlaceId,
      gatheringPlaceType: data.gatheringPlaceType,
      productObject: data.productObject,
      deliveryRangeId: data.deliveryRangeId,

      //ProductOutlet: data.ProductOutlet,
      SalesChannel: data.SalesChannel,
      deliveryCost: 10,
      orderNumber: data.orderNumber,
      dateOfSale: data.dateOfSale,
      dateOfDispatch: data.dateOfDispatch,
      note: data.note,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "addPartialShipment",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.create,
    };
  }
};

/*--- */
//transformed siparişin eklenmesi (parçalının direkte dönüşmesi)
const addShipmentTransformed = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      receiverId: data.receiverId,
      receiverType: data.receiverType,
      shipmentId: data.shipmentId,
      carId: data.carId,
      driverId: data.driverId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "addShipmentTransformed",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "transformed shipment created error! end point geçersiz olabilir!!!! (Transformed)"
    );
    return false;
  }
};


/*--- */
//direct'in onaylanması
const createDirectAssignment = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
      carId: data.carId,
      driverId: data.driverId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "assignDirectShipment",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "shipment assignment error! end point geçersiz olabilir!!!! (Direct)"
    );
    return false;
  }
};

/*--- */
//parçalının onaylanması
const createPartialAssignment = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "assignPartialShipment",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "shipment assignment error! end point geçersiz olabilir!!!! (Partial)"
    );
    return false;
  }
};

/*--- */
//direct siparişin durumunun yolda olarak işaretlenmesi
async function changeShipmentDirectStatus(data) {
  //teslim ediliyor
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
      shipmentStatuId: data.shipmentStatuId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "changeShipmentStatus",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.changeStatus,
    };
  }
}

/*--- */
//edit shipments ama şuan çalışmıyor
/* const updateShipment = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    withCredentials: true,

    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },

    body: JSON.stringify({
      shipmentId: data.shipmentId,
      receiverType: data.receiverType,
      receiverId: data.receiverId,
      gatheringPlaceType: data.gatheringPlaceType,
      gatheringPlaceId: data.gatheringPlaceId,
      productObject: data.productList,
      salesChannel: data.salesChannel,
      deliveryRangeId: data.deliveryRangeId,
      deliveryCatalogId: data.deliveryCatalogId,
      dateOfSale: data.dateOfSale,
      dateOfDispatch: data.dateOfDispatch,
      deliveryCost: 10,
      orderNumber: data.orderNumber,
      outletPlace: data.outletPlace[0],
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "update", requestOptions);

    // const tokenFetch = await fetch(API_URL + "update", {
    //   ...authHeader("PUT"),
    //   requestbody,
    // });

    const responce = await tokenFetch.json();

    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.updateShipment,
    };
  }
}; */

/*--- */
//parçalı'nın sürücü ataması
const assignDriverSubShipment = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      subShipmentId: data.subShipmentId,
      carId: data.carId,
      driverId: data.driverId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "assignDriverSubShipment",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "shipment assignment error! end point geçersiz olabilir!!!! (Direct)"
    );
    return false;
  }
};

/*--- */
//direkt siparişin teslim edilmesi
async function delivering(data) {
  //teslim ediliyor
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
      handDeliveryPerson: data.handDeliveryPerson
        ? data.handDeliveryPerson
        : "",
      verificationCode: data.verificationCode ? data.verificationCode : "",
      note: data.note,
      tc: data.tc,
      file: data.file,
      //trackingNumber: "123456789",
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "shipmentDelivering",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.delivering,
    };
  }
}

/*--- */
//direkt siparişin teslim edilememesi
async function deliveringFailed(data) {
  //teslim ediliyor
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
      note: data.note,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "shipmentDeliveringFailed",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.delivering,
    };
  }
}

/*--- */
//parçalı siparişin teslim edilmesi-alt sipariş
async function deliveringPartial(data) {
  //teslim ediliyor
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      subShipmentId: data.subShipmentId,
      handDeliveryPerson: data.handDeliveryPerson
        ? data.handDeliveryPerson
        : "",
      // verificationCode: data.verificationCode ? data.verificationCode : "",
      note: data.note,
      //  tc: data.tc,
       file: data.file,
      //trackingNumber: "123456789",
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "shipmentDeliveringPartial",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.delivering,
    };
  }
}

/*--- */
//Parçalı siparişin teslim edilememesi-alt sipariş
async function deliveringFailedPartial(data) {
  //teslim ediliyor
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      subShipmentId: data.subShipmentId,
      note: data.note,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "shipmentDeliveringFailedPartial",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.delivering,
    };
  }
}





/*projede kullanılmıyor.*/
/* const createPartialAssignmentToWareHouse = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
      carId: data.carId,
      driverId: data.driverId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "assignPartialShipmentToWareHouse",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "shipment assignment error! end point geçersiz olabilir!!!! (Parçalı-depoya ulaşan)"
    );
    return false;
  }
}; */

//eski yapı furkan
/* async function confirmShipmentPartial(data) {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      shipmentId: data.shipmentId,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "confirmShipmentPartial",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    //no need notification
  }
} */

/*--- */
//kullanılmıyor sanırım
/* async function getAllQueue() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getAllByQueue`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const allShipment = await shipmentResponse.json();

      return allShipment;
    }
  } catch (err) {
    console.log("getAllApprovedShipment try yakaladı err", err);
  }
} */
/*--- */
//Direct siparişlerin listelenmesi
async function getAllDirect() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  try {
    const shipmentResponse = await fetch(`${API_URL}getAllDirect`, authHeader("GET"));
    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const allShipment = await shipmentResponse.json();
      //console.log("nedir", allShipment);
     // console.log("requestOptions", requestOptions);

      return allShipment;
    }
  } catch (err) {
    console.log("getAllApprovedShipment try yakaladı err", err);
  }
}
/*--- */
//parçalı siparişlerin listelenmesi
async function getAllPartial() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getAllPartial`,
      authHeader("GET")
    );
    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const allPartialShipment = await shipmentResponse.json();
      //console.log("nedir", allPartialShipment);
      //  console.log("requestOptions", requestOptions);

      return allPartialShipment;
    }
  } catch (err) {
    console.log("getAllPartial try yakaladı err", err);
  }
}
/*--- */

/*--- */
//shipmentId'ye göre siparişin gösterilmesi
async function getById(shipmentId) {
  try {
    const receiverResponse = await fetch(
      API_URL + "getById?id=" + shipmentId,
      authHeader("GET")
    );

    if (!receiverResponse.ok) {
      return receiverResponse.ok;
    } else {
      const responce = await receiverResponse.json();

      return [responce];
    }
  } catch (err) {
    console.log(
      "shipment.service getById error end point geçersiz olabilir"
    );
  }



 /*  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getById?id=` + shipmentId,
      requestOptions
    );
    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const allShipment = await shipmentResponse.json();

      return allShipment;
    }
  } catch (err) {
    console.log("getAllApprovedShipment try yakaladı err", err);
  } */
}

/*--- */
//kullanılmıyor sanırım
/* async function deliveredAllShipment() {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      API_URL + "deliveredAll",
      requestOptions
    );
    const responce = await shipmentResponse.json();
    return responce;
  } catch (err) {
    console.log("deliveredAllShipment try yakaladı err", err);
  }
} */
/*--- */
//kullanılmıyor sanırım
/* async function deliveredTodayShipment() {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      API_URL + "deliveredToday",
      requestOptions
    );
    const responce = await shipmentResponse.json();
    return responce;
  } catch (err) {
    console.log("deliveredTodayShipment try yakaladı err", err);
  }
} */
/*--- */
//kullanılmıyor sanırım
/* async function deliveredMonthShipment() {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      API_URL + "deliveredMonth",
      requestOptions
    );
    const responce = await shipmentResponse.json();
    return responce;
  } catch (err) {
    console.log("deliveredMonthShipment try yakaladı err", err);
  }
} */
/*--- */
async function deleteShipment(id) {
  /*   try {
    const shipmentResponse = await fetch(
      API_URL + "delete/" + id,
      authHeader("DELETE")
    );
    const responce = await shipmentResponse.json();
    return responce;
  } catch (err) {
    console.log("deleteShipment try yakaladı err", err);
  } */

  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const tokenFetch = await fetch(API_URL + "delete/" + id, requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.deleteShipment,
    };
  }
}

/*--- */
//siparişin hareketlerinin getirilmesi (userevents sayfası)
async function getShipmentActivity() {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      API_URL + "shipmentActivity",

      requestOptions
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const allShipment = await shipmentResponse.json();

      return allShipment;
    }
  } catch (err) {
    console.log("getShipmentActivity try yakaladı err", err);
  }
}
/*--- */

//direkt sipariş ekleme sayfasında durum selectbox'ı
 async function getShipmentStatuses() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}shipmentStatuses/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getDeliveryCatalogs try yakaladı err", err);
  }
} 

/*--- */
//Yeniden Verify number verilmesi
async function refleshVerifyCodeNumber(data) {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const tokenFetch = await fetch(
      `${API_URL}refleshVerifyCodeNumber?shipmentId=${data.shipmentId}`,
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "refleshVerifyCodeNumber error! end point geçersiz olabilir!!!!"
    );
  }
}

/*--- */
//kullanılmıyor sanırım
/* async function getAllDelivered() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getAllDelivered/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getAllDelivered try yakaladı err", err);
  }
} */

/*--- */
//tüm sevkiyatların adeti
async function getAllShipment() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getAllShipment/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getAllShipment try yakaladı err", err);
  }
}
/*--- */
//bugün sevkiyata çıkacak sevkiyatların adeti
async function getDispatchToday() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getDispatchToday/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getDispatchToday try yakaladı err", err);
  }
}

/*--- */
//ileri tarihli sevkiyatların adeti
async function getFutureDate() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getFutureDate/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getFutureDate try yakaladı err", err);
  }
}

/*--- */
//onay bekleyen sevkiyatların adeti
async function getPendingShipments() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getPendingShipments/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getPendingShipments try yakaladı err", err);
  }
}

/*--- */
//depoya ulaşması beklenen sevkiyatların adeti
async function getPendingArriveWarehouse() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getPendingArriveWarehouse/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getPendingArriveWarehouse try yakaladı err", err);
  }
}

/*--- */
//sevkiyata dönüştürülecek sevkiyatların adeti
async function getConvertToDispatch() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getConvertToDispatch/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getConvertToDispatch try yakaladı err", err);
  }
}

/*--- */
//yolda olan sevkiyatların adeti
async function getOnTheWayShipments() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getOnTheWayShipments/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getOnTheWayShipments try yakaladı err", err);
  }
}

/*--- */
//teslim edilen sevkiyatların adeti
async function getDeliveredSuccess() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getDeliveredSuccess/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getDeliveredSuccess try yakaladı err", err);
  }
}
/*--- */
//teslim edilemeyen sevkiyatların adeti
async function getDeliveredFailed() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getDeliveredFailed/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getDeliveredFailed try yakaladı err", err);
  }
}
/*--- */
//bugün oluşturulan sevkiyatların adeti
async function getCreatedToday() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getCreatedToday/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getCreatedToday try yakaladı err", err);
  }
}
/*--- */
//nerede kullanıldığını bulamadım ama dursun
/* async function queueShipment(data) {
  const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      draggingShipmentId: data.draggingShipmentId,
      hoveredShipmentId: data.hoveredShipmentId,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "shipmentQueue", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
  }
} */

/*--- */
//ilçe getirme-artık texbox dan yazılıyor.
/* async function getDistrict() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}district/`,
      //  `${API_URL}showDistrict/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getDistrict try yakaladı err", err);
  }
} */

/*--- */
//il getirme-artık texbox dan yazılıyor.
/* async function getProvince() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}province/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getProvince try yakaladı err", err);
  }
} */

/*--- */
//satış kanallarini getirme
async function getSalesChannel() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}salesChannel/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getSalesChannel try yakaladı err", err);
  }
}
/*--- */
//kullanılmıyor sanırım
/* async function getByStoreWarehouse() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getByStoreWarehouse/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getByStoreWarehouse try yakaladı err", err);
  }
} */

/*--- */
//kullanılmıyor suan
/* async function changeOrderPreference(data) {
  const user = localStorage.getItem("user");
  const requestOptions = {
    withCredentials: true,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body: JSON.stringify({
      orderBy: data.orderBy,
    }),
  };
  try {
    const tokenFetch = await fetch(
      API_URL + "changeOrderPreference",
      requestOptions
    );
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    //no need notification
  }
} */

/*--- */
//kullanılmıyor suan
/* async function getByShipmentByQueue() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}getByQueue/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getByShipmentByQueue try yakaladı err", err);
  }
} */
/*--- */

export const shipmentService = {
  addShipmentDirect,
  addShipmentPartial,
  addShipmentTransformed,
  getAllDirect,
  getAllPartial,
  getById,
  changeShipmentDirectStatus,
  //updateShipment,
  //deliveredAllShipment,
  //deliveredTodayShipment,
  //deliveredMonthShipment,
  deleteShipment,
  getShipmentActivity,
  createDirectAssignment,
  createPartialAssignment,
  assignDriverSubShipment,
  //createPartialAssignmentToWareHouse,
  getShipmentStatuses,
  delivering,
  deliveringFailed,
  deliveringPartial,
  deliveringFailedPartial,
  refleshVerifyCodeNumber,
  //getAllDelivered,
  //
  getAllShipment,
  getDispatchToday,
  getFutureDate,
  getPendingShipments,
  getPendingArriveWarehouse,
  getConvertToDispatch,
  getOnTheWayShipments,
  getDeliveredSuccess,
  getDeliveredFailed,
  getCreatedToday,
  //
  //queueShipment,
  //getDistrict,
  //getProvince,
  getSalesChannel,
 // getByStoreWarehouse,
  //changeOrderPreference,
  //getByShipmentByQueue,
 // getAllQueue,
  //confirmShipmentPartial,
};
