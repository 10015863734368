//Customer add-edit
import React, { useState, useEffect } from "react";
/* Material */
import TextField from "@mui/material/TextField";
import { CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/material";

/*Notification */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*My Components */
import MyDivBox from "../common/myDivBox/MyDivBox";

/*Api */

import { authenticationService } from "../../../api/services/authentication.service";
/*Redux*/
import { useSelector, useDispatch } from "react-redux";

/*Notification */
import { showNotification } from "../../../store/action/notificationAction";

/*validation*/
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  currentPasswordV: Yup.string().required("boş bırakılamaz"),
  newPasswordV: Yup.string().required("boş bırakılamaz"),
  confirmPasswordV: Yup.string().required("boş bırakılamaz"),
});

export default function ChangePassword() {
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {};

  const handleChangePassword = async () => {
    let newPasswordV = getValues("newPasswordV");
    let currentPasswordV = getValues("currentPasswordV");
    let confirmPasswordV = getValues("confirmPasswordV");

    const tokenResponce = await authenticationService.changePassword({
      newPassword: newPasswordV,
      confirmPassword: confirmPasswordV,
      currentPassword: currentPasswordV,
    });
    if (tokenResponce) {
      dispatch(showNotification(tokenResponce.message, "success"));
    } else {
      dispatch(showNotification(tokenResponce.message, "error"));
    }
  };

  return (
    <>
      <Box
        component={Grid}
        container
        spacing={0}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12} sm={4} md={3} sx={{ p: 2 }}>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Mevcut Şifre"
            {...register("currentPasswordV", { value: "" })}
            error={errors.currentPasswordV ? true : false}
            helperText={errors.currentPasswordV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ p: 2 }}>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Yeni Şifre"
            {...register("newPasswordV", { value: "" })}
            error={errors.newPasswordV ? true : false}
            helperText={errors.newPasswordV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ p: 2 }}>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Tekrar Yeni Şifre"
            {...register("confirmPasswordV", { value: "" })}
            error={errors.confirmPasswordV ? true : false}
            helperText={errors.confirmPasswordV?.message}
          />
        </Grid>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <button
            onClick={handleSubmit(handleChangePassword)}
            style={{ margin: "10px" }}
          >
            Değiştir
          </button>
        </div>
      </div>
    </>
  );
}
