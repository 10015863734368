import React, { useState, useEffect, lazy } from "react";
import Loadable from "ui-component/Loadable";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import jwt_decode from "jwt-decode";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// defaultTheme
// test
import themes from "themes";
// project imports
import NavigationScroll from "layout/NavigationScroll";
import Login from "views/pages/authentication/Login";
import EditShipment from "views/components/shipment/directShipmentType/editShipment/EditDirectShipment";
//import { useLocation } from "react-router";

/*ProtectedRoute */
import RoleBasedRoute from "./routes/RoleBasedRoute";

/*Pages */
import { logout } from "./store/action/authAction";
import TabPanel from "views/components/userProfileAndSetting/TabPanel";
import ShipmentPrint from "views/components/shipment/shipmentPrint/ShipmentPrint";
//import ShipmentQueue from "views/pages/shipment/ShipmentQueue";
import PickUpPackage from "views/components/pickupPackage/PickUpPackage";
//import QrCode from "views/components/shipment/qr/Qr";
import AddPartialShipment from "views/components/shipment/partialShipmentType/addShipment/AddPartialShipment";
import AddDirectShipment from "views/components/shipment/directShipmentType/addShipment/AddDirectShipment";
//import ShipmentPrintLabel from "views/components/shipment/shipmentPrintLabel/ShipmentPrintLabel";
/*Lazy loading */

const MainLayout = Loadable(lazy(() => import("layout/MainLayout")));

const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const ProductPage = Loadable(lazy(() => import("views/pages/product/Product")));

const CustomerPage = Loadable(
  lazy(() => import("views/pages/receiversAndSenders/Customer"))
);
const StorePage = Loadable(
  lazy(() => import("views/pages/receiversAndSenders/Store"))
);
const WarehousePage = Loadable(
  lazy(() => import("views/pages/receiversAndSenders/Warehouse"))
);
const HomePage = Loadable(lazy(() => import("views/pages/home/Home")));
const ShipmentDirectDetailPage = Loadable(
  lazy(() =>
    import(
      "views/components/shipment/directShipmentType/detail/ShipmentDirectDetail"
    )
  )
);
const ShipmentPartialDetailPage = Loadable(
  lazy(() =>
    import(
      "views/components/shipment/partialShipmentType/detail/ShipmentPartialDetail"
    )
  )
);
const SubShipmentDetailPage = Loadable(
  lazy(() =>
    import(
      "views/components/shipment/partialShipmentType/detail/SubShipmentDetail"
    )
  )
);
const ShipmentPage = Loadable(
  lazy(() => import("views/components/shipment/shipmentList/ShipmentList"))
);

/* const ShipmentAddPage = Loadable(
  lazy(() => import("views/components/shipment/shipmentList/ShipmentList"))
);
const ShipmentEditPage = Loadable(
  lazy(() =>
    import(
      "views/components/shipment/directShipmentType/editShipment/EditDirectShipment"
    )
  )
); */
const UserProfilePage = Loadable(
  lazy(() => import("views/components/userProfileAndSetting/TabPanel"))
);
const ProfilePage = Loadable(
  lazy(() => import("views/components/userProfileAndSetting/Profile"))
);
const DeliverySuccessFormForCustomer = Loadable(
  lazy(() => import("views/components/delivery/DeliverySuccessFormForCustomer"))
);
const DeliveryFailedFormForCustomer = Loadable(
  lazy(() => import("views/components/delivery/DeliveryFailedFormForCustomer"))
);
const DeliverySuccessFormForCustomerPartial = Loadable(
  lazy(() => import("views/components/delivery/DeliverySuccessFormForCustomerPartial"))
);
const DeliveryFailedFormForCustomerPartial = Loadable(
  lazy(() => import("views/components/delivery/DeliveryFailedFormForCustomerPartial"))
);
const DeliverySuccessFormForWarehouse = Loadable(
  lazy(() =>
    import("views/components/delivery/DeliverySuccessFormForWarehouse")
  )
);
const DriverRegionPage = Loadable(
  lazy(() => import("views/pages/driverRegion/DriverRegion"))
);
const UserEventsPage = Loadable(
  lazy(() => import("views/components/admin/UserActivity"))
);
const RolePage = Loadable(
  lazy(() => import("views/components/userProfileAndSetting/Role"))
);
const TestPage = Loadable(
  lazy(() => import("views/components/DENEME/testPage"))
);

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  //let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  //role göre route a erişme izni verilmeli o yüzden farklı routesler kullandım.Bunların hepsi aynı anda aktif olmayacak
  //gelen role e göre switch case ile biri aktif oalcak
  const customization = useSelector((state) => state.customization);
  const { role, isLoggedIn, user, token } = useSelector((state) => state.auth);

  const currentTime = new Date();

  async function tokenExpired() {
    try {
      const token = await localStorage.getItem("user");
      if (token) {
        var decoded = jwt_decode(token);
        const timestamp = decoded.exp * 1000;
        var date = new Date(timestamp);
        if (date <= currentTime) {
          dispatch(logout());
          localStorage.clear();
        }
      }
    } catch (error) {
      const mute = error;
    }
  }

  useEffect(() => {
    tokenExpired();
    setIsLoading(false);
  }, [navigate, dispatch, role]); //her path değiştiğinde de kontro ledilebilir navigate eklemek yeterli

  // if (isLoading) {
  //   return;
  //   <WaitingPage />;
  // }
  // if (role === null) {
  //   return <WaitingPage />;
  // }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          <NavigationScroll>
            <Routes>
              {/* working on it, not ready to open */}
              <Route path="/UserProfilePage" element={<UserProfilePage />} />
              <Route path="/ProfilePage" element={<ProfilePage />} />
             {/* <Route path="/sirala" element={<ShipmentQueue />} /> */}
              <Route path="pickUpPackage" element={<PickUpPackage />} />
              {/*  <Route path="/qr" element={<QrCode />} /> */}
              <Route
                path="/adddirectshipment"
                element={<AddDirectShipment />}
              />
              <Route
                path="/adddpartialshipment"
                element={<AddPartialShipment />}
              />

              {/* <Route path="account-profile" element={<TabPanel />} /> */}
              <Route path="account-profile" element={<TabPanel />} />

              {/* public route */}
              <Route path="/login" element={<Login />} />

              {/* Need Auth route */}
              <Route path="" element={<MainLayout />}>
                <Route path="panel" index element={<HomePage role={role} />} />
                <Route
                  path="ShipmentDirectDetail"
                  index
                  element={
                    <RoleBasedRoute
                      roles={[
                        "Admin",
                        "Driver",
                        "Store",
                        "Warehouse",
                        "DataEntry",
                        "Supervisor",

                      ]}
                    >
                      <ShipmentDirectDetailPage />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="shipmentPartialDetail"
                  index
                  element={
                    <RoleBasedRoute
                      roles={[
                        "Admin",
                        "Driver",
                        "Store",
                        "Warehouse",
                        "DataEntry",
                        "Supervisor",
                      ]}
                    >
                      <ShipmentPartialDetailPage />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="SubShipmentDetail"
                  index
                  element={
                    <RoleBasedRoute
                      roles={[
                        "Admin",
                        "Driver",
                        "Store",
                        "Warehouse",
                        "DataEntry",
                        "Supervisor",
                      ]}
                    >
                      <SubShipmentDetailPage />
                    </RoleBasedRoute>
                  }
                />
                <Route path="shipments" element={<ShipmentPage />}>
                  {/* getpending,ontheway ,convertToDispatch*/}
                  <Route path=":id" element={<ShipmentPage />} />
                  <Route
                    path="orderNumber/:orderNumber"
                    element={<ShipmentPage />}
                  />
                 {/*  <Route
                    path="orderShipments"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry"]}>
                        <ShipmentQueue /> 
                      </RoleBasedRoute>
                    }
                  /> */}
                </Route>
                <Route path="shipmentAdd">
                  <Route
                    path="direct"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <AddDirectShipment />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="partial"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <AddPartialShipment />
                      </RoleBasedRoute>
                    }
                  />
                </Route>{" "}
                <Route path="shipmentEdit">
                  <Route
                    path="direct/:id"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <AddDirectShipment />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="partial/:id"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <AddPartialShipment />
                      </RoleBasedRoute>
                    }
                  />
                </Route>
                <Route
                  path="products"
                  element={
                    <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                      <ProductPage />
                    </RoleBasedRoute>
                  }
                />
                <Route path="receiverSender">
                  <Route
                    path="customer"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <CustomerPage />
                      </RoleBasedRoute>
                    }
                  />

                  <Route
                    path="customer/edit/:id"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <CustomerPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="store"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <StorePage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="store/edit/:id"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <StorePage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="warehouse"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <WarehousePage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="warehouse/edit/:id"
                    element={
                      <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                        <WarehousePage />
                      </RoleBasedRoute>
                    }
                  />
                </Route>
                <Route
                  path="deliverySuccessFormForCustomer"
                  element={
                    <RoleBasedRoute roles={["Admin","Driver", "DataEntry","Supervisor"]}>
                      <DeliverySuccessFormForCustomer />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="deliveryFailedFormForCustomer"
                  element={
                    <RoleBasedRoute roles={["Admin","Driver", "DataEntry","Supervisor"]}>
                      <DeliveryFailedFormForCustomer />
                    </RoleBasedRoute>
                  }
                />
                  <Route
                  path="deliverySuccessFormForCustomerPartial"
                  element={
                    <RoleBasedRoute roles={["Admin","Driver", "DataEntry","Supervisor"]}>
                      <DeliverySuccessFormForCustomerPartial />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="deliveryFailedFormForCustomerPartial"
                  element={
                    <RoleBasedRoute roles={["Admin","Driver", "DataEntry","Supervisor"]}>
                      <DeliveryFailedFormForCustomerPartial />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="deliverySuccessFormForWarehouse"
                  element={
                    <RoleBasedRoute roles={["Driver","Supervisor"]}>
                      <DeliverySuccessFormForWarehouse />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="regionsAndDrivers"
                  element={
                    <RoleBasedRoute roles={["Admin", "DataEntry","Supervisor"]}>
                      <DriverRegionPage />
                    </RoleBasedRoute>
                  }
                />
                <Route
                  path="regionsAndDrivers/edit/:id"
                  element={
                    <RoleBasedRoute roles={["Admin","Supervisor"]}>
                      <DriverRegionPage />
                    </RoleBasedRoute>
                  }
                />
                 <Route
                  path="userEvents"
                  element={
                    <RoleBasedRoute roles={["Admin","Supervisor"]}>
                      <UserEventsPage />
                    </RoleBasedRoute>
                  }
                />
                   <Route
                  path="roleManagement"
                  element={
                    <RoleBasedRoute roles={["Admin"]}>
                      <RolePage />
                    </RoleBasedRoute>
                  }
                />
                 <Route
                  path="testPage"
                  element={
                    <RoleBasedRoute roles={["Admin"]}>
                      <TestPage />
                    </RoleBasedRoute>
                  }
                />
              </Route>

               <Route path="/shipmentprint" element={<ShipmentPrint />} />
            {/*   <Route
                path="shipmentprintLabel"
                element={<ShipmentPrintLabel />}
              /> */}
            </Routes>
          </NavigationScroll>
        </DndProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
