import {
  FETCH_SHIPMENTS_REQUEST,
  FETCH_SHIPMENTS_SUCCESS,
  FETCH_SHIPMENTS_FAILURE,
} from "store/action/types";

const initialState = {
  loading: false,
  shipments: [],
  error: "",
  lastFetched: null,
};
const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIPMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipments: action.payload,
        error: "",
        lastFetched: Date.now(),
      };
    case FETCH_SHIPMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        shipments: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { shipmentReducer };
