export default function authHeader(Method) {
  const user = localStorage.getItem("user");

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",

    Authorization: `Bearer ${user}`,
  };
  const requestOptions = {
    method: Method,
    headers: headers,
  };

  if (user) {
    return requestOptions;
  } else {
    return {};
  }
}
