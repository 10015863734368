import {
    ASSIGN_SHIPMENT_PARTIAL,
    ASSIGN_SHIPMENT_PARTIAL_MODAL_CLOSE,
    ASSIGN_SHIPMENT_PARTIAL_MODAL_OPEN,
  } from "../action/types";
  
  const initialState = {
    shipment: "",
    car: "",
    driver: "",
    modalOpen: false,
  };
  
  function shipmentPartialAssignmentReducer(state = initialState, action) {
    switch (action.type) {
      case ASSIGN_SHIPMENT_PARTIAL:
        return {
          ...state,
          shipment: action.payload.subOrderNo,
          //car: action.payload.shipment.car,   /*değerin tanımlı olmaması durumunda undefined olarak gelmemesi için ? koyduk*/
          //driver: action.payload.shipment.driver,  /*değerin tanımlı olmaması durumunda undefined olarak gelmemesi için ? koyduk*/
        };
      case ASSIGN_SHIPMENT_PARTIAL_MODAL_OPEN:
        return {
          ...state,
          modalOpen: true,
        };
      case ASSIGN_SHIPMENT_PARTIAL_MODAL_CLOSE:
        return {
          ...state,
          modalOpen: false,
        };
      default:
        return state;
    }
  }
  
  export { shipmentPartialAssignmentReducer };
  