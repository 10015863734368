import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RoleBasedRoute = ({ roles, children }) => {
  const userRole = useSelector((state) => state.auth.role);
  const navigate = useNavigate();

  useEffect(() => {
    if (!roles.includes(userRole)) {
      navigate("/");
    }
  }, [roles, userRole, navigate]);

  return roles.includes(userRole) ? children : null;
};

export default RoleBasedRoute;
