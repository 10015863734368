import authHeader from "../auth-header";
import config from "./config";

const API_URL = config.basename + "Products/";

const allProduct = "";
async function getProduct() {
  try {
    const productResponse = await fetch(API_URL, authHeader("GET"));
    return productResponse.json();
  } catch (err) {
    console.log("product api isteği başarısız:", err);
  }
}
/*--- */
/* const createProduct = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      name: data.productName,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "add", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log("createProduct error! end point geçersiz olabilir!!!!");
    return false;
  }
}; */
/*--- */
/* const updateProduct = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      id: data.id,
      name: data.productName,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "update", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log("createProduct error! end point geçersiz olabilir!!!!");
    return false;
  }
}; */
export const productService = {
  getProduct,
 /*  createProduct,
  updateProduct, */
};
