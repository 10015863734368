// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_CHECKED_SUCCESS = "LOGIN_CHECKED_SUCCESS";
export const LOGIN_CHECKED_FAIL = "LOGIN_CHECKED_FAIL";

export const USER_LOADING = "USER_LOADING";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

/*Shipment */

export const FETCH_SHIPMENTS_REQUEST = "FETCH_SHIPMENTS_REQUEST";
export const FETCH_SHIPMENTS_SUCCESS = "FETCH_SHIPMENTS_SUCCESS";
export const FETCH_SHIPMENTS_FAILURE = "FETCH_SHIPMENTS_FAILURE";

/*Product */
export const FETCH_PRODUCTS_REQUEST = "FETCH_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const ASSIGN_SHIPMENT_DIRECT = "ASSIGN_SHIPMENT_DIRECT";
export const ASSIGN_SHIPMENT_DIRECT_MODAL_OPEN =
  "ASSIGN_SHIPMENT_DIRECT_MODAL_OPEN";
export const ASSIGN_SHIPMENT_DIRECT_MODAL_CLOSE =
  "ASSIGN_SHIPMENT_DIRECT_MODAL_CLOSE";

  export const ASSIGN_SHIPMENT_PARTIAL = "ASSIGN_SHIPMENT_PARTIAL";
export const ASSIGN_SHIPMENT_PARTIAL_MODAL_OPEN =
  "ASSIGN_SHIPMENT_PARTIAL_MODAL_OPEN";
export const ASSIGN_SHIPMENT_PARTIAL_MODAL_CLOSE =
  "ASSIGN_SHIPMENT_PARTIAL_MODAL_CLOSE";


  export const ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT = "ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT";
export const ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT_MODAL_OPEN =
  "ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT_MODAL_OPEN";
export const ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT_MODAL_CLOSE =
  "ASSIGN_SHIPMENT_PARTIAL_TO_DIRECT_MODAL_CLOSE";


/*Customer */
export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAIL = "FETCH_CUSTOMER_FAIL";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";

/*receiverAndSender */
export const FETCH_RECEIVERSENDER_REQUEST = "FETCH_RECEIVERSENDER_REQUEST";
export const FETCH_RECEIVERSENDER_SUCCESS = "FETCH_RECEIVERSENDER_SUCCESS";
export const FETCH_RECEIVERSENDER_FAIL = "FETCH_RECEIVERSENDER_FAIL";
