import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  LOGIN_CHECKED_FAIL,
  LOGIN_CHECKED_SUCCESS,
} from "./types";
import jwt_decode from "jwt-decode";

import { authenticationService } from "api/services/authentication.service";

export const login = (email, password) => (dispatch) => {
  //return ile
  return authenticationService.login(password, email).then(
    (data) => {
      if (data === false) {
        localStorage.setItem("user", "");
      } else {
        localStorage.setItem("user", data);
      }
      let role = jwt_decode(data).role;
      let isAuth = true;
      localStorage.setItem("role", role);
      localStorage.setItem("isAuth", isAuth);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGIN_FAIL,
  });
  return Promise.resolve();
};

export const checkAuth = () => (dispatch) => {
  return authenticationService.session().then(
    (data) => {
      if (data === true) {
        dispatch({
          type: LOGIN_CHECKED_SUCCESS,
        });
        return true; // add this line
      } else {
        dispatch({
          type: LOGIN_CHECKED_FAIL,
        });
        return false; // add this line
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_CHECKED_FAIL,
      });

      return Promise.reject();
    }
  );
};
