import React from "react";
//başlık kısmı olan radiuslu beyaz bir kutu içine childeren alabiliyor.
export default function MyDivBox({ title, children, style }) {
  return (
    <div
      className="alici"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        width: "100%",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          borderBottom: "1px solid #ebedf2",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <p
          style={{
            margin: "0px",
            marginLeft: "7px",
          }}
        >
          {title}
        </p>
        {/* <div>bbbbb</div> */}
      </div>
    
      {children}
    </div>
  );
}
