import styled from "styled-components";

export const Row = styled.div`
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
  display: grid;

  grid-template-columns: repeat(${(props) => props.cols || 2}, 1fr);

  .remove-btn {
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    color: red;
    border: 1px solid red;
  }
  .add-btn {
    background-color: #662494;

    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;

    color: #662494;
    border: 1px solid #662494;
  }
`;

export const Button = styled.button`
  border-radius: 50px;
  white-space: nowrap;
  padding: 10px 22px;
  background-color: #662484;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;

export const TextInputTitle = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  align-content: center;
  align-items: center;
  margin-left: 10px;
`;
export const Col = styled.div`
  margin: 10px;
`;
