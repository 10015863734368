import styled from "styled-components";

export const ContactUsSelect = styled.select`
  border: 1px solid gray;
  outline: none;
  width: 100%;
  background-color: white;
  color: #333;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 8px;

  resize: vertical;

`;
export const Option = styled.option`
padding: 10px;
`;
export const Button = styled.button`
  border-radius: 50px;
  white-space: nowrap;
  padding: 10px 22px;
  background-color: #662484;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;



export const Row = styled.div`
border-radius: 10px;
background-color: white;
margin-top: 20px;

.remove-btn{
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  color:red;
  border: 1px solid red;
}
.add-btn{
  background-color: #662494;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  color:white;
  border: 1px solid #662494;
  margin: 0px;
  padding: 2px;
}


`;
export const Row2 = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 20px;


`;