import styled, { keyframes } from "styled-components";

const flashingAnimation = keyframes`
   0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div`
  .flashing-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: ${flashingAnimation} 1s linear infinite;
  }
`;
