import React from "react";
import Modal from "@mui/material/Modal";

export default function ModalPopUp({ open, setOpen, children }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{children}</div>
      </Modal>
    </div>
  );
}
