import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// material-ui
/*validation*/
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// project imports
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
// ==============================|| SAMPLE PAGE ||============================== //
import { login, checkAuth } from "../../../store/action/authAction";
import MainCard from "ui-component/cards/MainCard";
import { authenticationService } from "../../../api/services/authentication.service";
import WaitingPage from "views/components/waitingPage/WaitingPage";
//notification
import Notification from "views/components/common/notification/Notification";
import { showNotification } from "../../../store/action/notificationAction";
import Loader from "ui-component/Loader";
import { CircularProgress } from "@mui/material";
import Loadable from "ui-component/Loadable";

export default function Login() {
  const [circleProgress, setCircleProgress] = useState(false);

  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { name } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    mailV: Yup.string().required("bu alan zorunlu"),
    passwordV: Yup.string().required("bu alan zorunlu"),
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();
  const currentPath = useLocation().pathname;

  let navigate = useNavigate();

  const handleLogin = () => {
    setCircleProgress(true);
    let userNameV = getValues("mailV");
    let passwordV = getValues("passwordV");
    let responce = dispatch(login(userNameV, passwordV))
      .then((a) => {
        // dispatch(showNotification("Giriş Başarılı", "success"));
        navigate("/panel", { replace: true });
        setCircleProgress(false);
      })
      .catch(() => {
        dispatch(showNotification("Giriş Başarısız", "error"));

        setLoading(false);
        setCircleProgress(false);
      });
  };

  useEffect(() => {
    setLoading(false);
    // redirect to home if already logged in

    if (user !== null && user !== "null" && user !== undefined) {
      navigate("/panel");
    } else {
      navigate("/login");
    }
  }, [navigate, dispatch]);

  // if (loading) {
  //   return <WaitingPage />;
  // }

  return (
    <>
      {/* {loading === true ? <Loader /> : null} */}

      <Grid
        container
        component="main"
        sx={{ height: "100vh", boxSizing: "border-box" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            height: "100vh",
            boxSizing: "border-box",
            backgroundImage:
              "url(https://files.doremusicxpress.com/images/dorestore.jpg)",
            backgroundRepeat: "no-repeat",

            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              boxSizing: "border-box",
              my: 5,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              className="LogoContainer-img"
              src={require("../../../assets/images/xpressLogo.png")}
              style={{ width: "160px", marginTop: "40px" }}
            />

            <Typography component="h1" variant="h5" color="primary">
              Giriş Yap
            </Typography>
            <Box component="form" noValidate sx={{ mt: 5 }}>
              <TextField
                label="E-posta"
                {...register("mailV")}
                error={errors.mailV ? true : false}
                helperText={errors.mailV?.message}
                fullWidth
                sx={{ marginBottom: "15px" }}
              />
              <TextField
                label="Şifre"
                type="password"
                {...register("passwordV")}
                error={errors.passwordV ? true : false}
                helperText={errors.passwordV?.message}
                fullWidth
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Beni hatırla"
              /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableRipple={true}
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit(handleLogin)}
              >
                Giriş Yap {circleProgress ? <Loader /> : null}
              </Button>

              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Şifremi Unuttum
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Notification />
    </>
  );
}

// import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// // material-ui

// /*Notification */
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// // project imports
// import { useDispatch, useSelector } from "react-redux";
// import { Navigate, useNavigate, useLocation } from "react-router-dom";
// // ==============================|| SAMPLE PAGE ||============================== //
// import { login, checkAuth } from "../../../store/action/authAction";
// import MainCard from "ui-component/cards/MainCard";
// import { authenticationService } from "../../../api/services/authentication.service";
// import WaitingPage from "views/components/waitingPage/WaitingPage";
// /*validation*/
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import moment from "moment/moment";

// export default function Login() {
//   const [loading, setLoading] = useState(true);
//   const [userName, setUserName] = useState("");
//   const [password, setPassword] = useState("");

//   const { isLoggedIn } = useSelector((state) => state.auth);
//   const { user } = useSelector((state) => state.auth);
//   const { name } = useSelector((state) => state.auth);
//   const { token } = useSelector((state) => state.auth);

//   console.log("nedir token", token);
//   const dispatch = useDispatch();
//   const currentPath = useLocation().pathname;

//   let navigate = useNavigate();
//   console.log("isim ve password", userName + password);

//   const handleLogin = (e) => {
//     e.preventDefault();
//     dispatch(login(userName, password))
//       .then((a) => {
//         navigate("/panel");
//         console.log("a", a);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//   };

//   const validationSchema = Yup.object().shape({
//     eMailV: Yup.string().required("bu alan boş bırakılamaz"),
//     passwordV: Yup.string().required("bu alan boş bırakılamaz"),
//   });
//   const notify = (prop) =>
//     toast.success(prop, {
//       position: "bottom-center",
//       autoClose: 1000,

//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,

//     });
//   const {
//     register,
//     control,
//     handleSubmit,
//     getValues,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(validationSchema),
//   });
//   // console.log("orderNumberV", getValues("orderNumberV"));
//   // console.log("carV", getValues("carV"));
//   // console.log("deliveryRangeV", getValues("deliveryRangeV"));
//   // console.log("salesChannelV", getValues("salesChannelV"));
//   // console.log("senderV", getValues("senderV"));
//   // console.log("receiverV", getValues("receiverV"));
//   // console.log("products", getValues("products"));

//   const onSubmit = (data) => {
//     console.log("onSubmit");

//     handleLogin();
//   };

//   useEffect(() => {
//     setLoading(false);
//     if (isLoggedIn) navigate("/panel");
//   }, [isLoggedIn, navigate]);

//   if (loading) {
//     return <WaitingPage />;
//   }

//   return (
//     <>
//       <ToastContainer
//         position="top-right"
//         autoClose={5000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//         style={{ width: "500px" }}
//       />
//       <Grid
//         container
//         component="main"
//         sx={{ height: "100vh", boxSizing: "border-box" }}
//       >
//         <CssBaseline />
//         <Grid
//           item
//           xs={false}
//           sm={4}
//           md={7}
//           sx={{
//             height: "100vh",
//             boxSizing: "border-box",
//             backgroundImage: "url(https://source.unsplash.com/random)",
//             backgroundRepeat: "no-repeat",

//             backgroundColor: (t) =>
//               t.palette.mode === "light"
//                 ? t.palette.grey[50]
//                 : t.palette.grey[900],
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         />
//         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//           <Box
//             sx={{
//               boxSizing: "border-box",
//               my: 5,
//               mx: 4,
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <img
//               className="LogoContainer-img"
//               src={require("../../../assets/images/xpressLogo.png")}
//               style={{ width: "160px", marginTop: "40px" }}
//             />

//             <Typography component="h1" variant="h5" color="primary">
//               Giriş Yap
//             </Typography>
//             <Box component="form" noValidate sx={{ mt: 1 }}>
//               <TextField
//                 onChange={(e) => setUserName(e.target.value)}
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Adresi"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 {...register("eMailV")}
//                 error={errors.eMailV ? true : false}
//                 helperText={errors.eMailV?.message}
//               />
//               <TextField
//                 onChange={(e) => setPassword(e.target.value)}
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Şifre"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 {...register("passwordV")}
//                 error={errors.passwordV ? true : false}
//                 helperText={errors.passwordV?.message}
//               />
//               <FormControlLabel
//                 control={<Checkbox value="remember" color="primary" />}
//                 label="Beni hatırla"
//               />

//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 disableRipple={true}
//                 sx={{ mt: 3, mb: 2 }}
//                 onClick={handleLogin}
//               >
//                 Giriş Yap
//               </Button>

//               {/* <Grid container>
//                 <Grid item xs>
//                   <Link href="#" variant="body2">
//                     Şifremi Unuttum
//                   </Link>
//                 </Grid>
//               </Grid> */}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// }
