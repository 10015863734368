import React, { useEffect, useState } from "react";
import {
  Header,
  DeliveryInfoSection,
  SenderSection,
  ReceiverSection,
  ProductContent,
  TableTitle,
} from "./Elements";
import Logo from "../../../../assets/images/xpressLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import QrCode from "../Qr/Qr";

export default function ShipmentPrint() {
  const location = useLocation();
  let navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState([]);
  const [productList, setProductList] = useState([
    {
      name: "",
      quantity: 1,
      outletChannelId: 1,
      outletChannelType: "",
      id: 0,
      outletChannelName: "",
    },
  ]);

  const [selectedReceiverObj, setSelectedReceiverObj] = useState();
  const [shipmentId, setShipmentId] = useState();
  const [distributionWarehouse, setDistributionWarehouse] = useState();
  const [trackingNumber, setTrackingNumber] = useState();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const { selectedRows } = location.state;
    console.log(selectedRows);
    setSelectedRow(selectedRows);
    setSelectedReceiverObj(selectedRows[0].receiver);
    setTrackingNumber(selectedRows[0].shipment.trackingNumber);
    setShipmentId(selectedRows[0].shipment.shipmentId);
    setDistributionWarehouse(selectedRows[0].distributionWarehouse);

    let productLength = location.state.selectedRows[0].shipmentDetails.length;

    let temp = [];

    for (let i = 0; i < productLength; i++) {
      let shipmentName = selectedRows[0].shipmentDetails[i].product.name;
      let shipmentId = selectedRows[0].shipmentDetails[i].product.id;
      let shipmentQuantity = selectedRows[0].shipmentDetails[i].quantity;
      let outletChannelId =
        selectedRows[0].shipment.shipmentDetails[i].outletChannelId;
      let outletChannelType =
        selectedRows[0].shipmentDetails[i].outletChannelType;
      let outletChannelName =
        selectedRows[0].shipmentDetails[i].outletChannel.name;

      temp.push({
        name: shipmentName,
        id: shipmentId,
        quantity: shipmentQuantity,
        outletChannelId: outletChannelId,
        outletChannelType: outletChannelType,
        outletChannelName: outletChannelName,
      });
    }
    setProductList(temp);
  }, [location.state]);

  return (
    <div
      className="container"
      style={{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        width: "600px",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      {selectedRow &&
        selectedRow.map((p, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <img src={Logo} height="50" alt="Xpress Logo" />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <QrCode value={trackingNumber} />
              </div>
            </Header>

            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#555",
                marginBottom: "20px",
                borderBottom: "2px dotted #CCC",
                paddingBottom: "10px",
                color: "#6a0dad", // Violet color for the date
                fontWeight: "bold",
              }}
            >
              Tarih: {moment.utc(date).format("MM/DD/YY")}
            </div>

            <DeliveryInfoSection style={{ marginBottom: "20px",  paddingBottom: "20px", textAlign: "center" }}>
              <ReceiverSection>
                <p style={{ color: "#6a0dad" }}>
                  <b>GÖNDERİCİ: </b>
                  {distributionWarehouse.name}
                </p>
                <p>
                  <b>ADRES: </b>
                  {distributionWarehouse.address}
                </p>
                <p>
                  <b>Telefon: </b>
                  {distributionWarehouse.phone}
                </p>
              </ReceiverSection>

              <ReceiverSection style={{ borderRight: "none" }}>
                <p style={{ color: "#6a0dad" }}>
                  <b>ALICI: </b>
                  {selectedReceiverObj.name}
                </p>
                <p>
                  <b>ADRES: </b>
                  {selectedReceiverObj.address +
                    " " +
                    selectedReceiverObj.neighborhood +
                    " " +
                    selectedReceiverObj.district +
                    " / " +
                    selectedReceiverObj.city}
                </p>
                <p>
                  <b>Telefon: </b>
                  {selectedReceiverObj.phone}
                </p>
              </ReceiverSection>
            </DeliveryInfoSection>

            <ProductContent>
              <div style={{ width: "100%", textAlign: "center" }}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontWeight: "bold",
                    borderBottom: "2px solid #6a0dad", // Violet color for the border
                    paddingBottom: "10px",
                    marginBottom: "10px",
                    color: "#6a0dad", // Violet color for the text
                  }}
                >
                  <span>Ürün/İçerik</span>
                  <span>Ürün Adedi</span>
                </p>
                {productList.map((a) => (
                  <div
                    key={a.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "5px",
                      padding: "10px 0",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <TableTitle>{a.name}</TableTitle>
                    <TableTitle>{a.quantity}</TableTitle>
                  </div>
                ))}
              </div>
            </ProductContent>
          </div>
        ))}
    </div>
  );
}
