const initialState = {
  isLoading: false,
};

function upsideLineLoaderReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_LOADER ":
      return {
        ...state,
        isLoading: true,
      };
    case "HIDE_LOADER ":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export { upsideLineLoaderReducer };
