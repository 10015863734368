import {
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_FAIL,
  FETCH_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
} from "../action/types";

const initialState = {
  customers: [],
  loading: false,
  error: null,
  message: "",
  refresh: false,
};

function customerReducer(state = initialState, action) {
  switch (action.type) {
    /*fetch */
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        loading: false,
      };
    case FETCH_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /*CRUD */
    case ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        refresh: false,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: true,
      };
    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        refresh: false,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: true,
      };
    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export { customerReducer };
