import authHeader from "../auth-header";
import config from "./config";
import axios from "axios";
import { apiMessages } from "../constant/messages";

const API_URL = config.basename + "Auth/";

/*--- */
const changePassword = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      currentPassword: data.currentPassword,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "changePassword", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log("changePassword error! end point geçersiz olabilir!!!!");
    return false;
  }
};

const login = async (password, email) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      password: password,
      email: email,
    }),
  };

  try {
    const tokenFetch = await fetch(API_URL + "login", requestOptions);

    if (tokenFetch.ok) {
      const responce = await tokenFetch.json();
      return responce;
    }
  } catch (err) {
    const mute = err;

    console.log("login error! end point geçersiz olabilir!!!!");
    return false;
  }
};

// function logout() { //reducer'da olacak
//   localStorage.clear();
// }

const session = async () => {
  const token = localStorage.getItem("user");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const tokenFetch = await fetch(API_URL + "isAuth", requestOptions);
    if (tokenFetch.ok) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    const mute = err;

    console.log("session error! end point geçersiz olabilir!!!!");
    return false;
  }
};


async function getClaimCategories() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}claimCategories/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getClaimCategories try yakaladı err", err);
  }
}


async function getPermissions() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}permissions/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getPermissions try yakaladı err", err);
  }
}

const addRole = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      UserTypeId: data.UserTypeId,
      PermissionId:data.PermissionId,
    

    }),
  };
  try {
  
    const tokenFetch = await fetch(API_URL + "addRole", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log(
      "addRole  error! end point geçersiz olabilir!!!!"
    );
    return false;
  }
};




async function deleteUserPermission(id) {
   const user = localStorage.getItem("user");

  const requestOptions = {
    withCredentials: true,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${user}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const tokenFetch = await fetch(API_URL + "deleteUserPermission/" + id, requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.deleteUserPermission,
    };
  }
}


async function getRolesAll() {
  const requestOptions = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };
  try {
    const shipmentResponse = await fetch(
      `${API_URL}rolesAll/`,
      authHeader("GET")
    );

    if (!shipmentResponse.ok) {
      return shipmentResponse.ok;
    } else {
      const responce = await shipmentResponse.json();

      return responce;
    }
  } catch (err) {
    console.log("getRolesAll try yakaladı err", err);
  }
}
// async function session() {
//   //true yada false dönüyor
//   const user = localStorage.getItem("user");

//   console.log("session service token", user);
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${user}`,
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Credentials": "true",
//     },
//   };
//   console.log("hatayı verdi1");

//   try {
//     await axios
//       .get(API_URL + "isAuth", {
//         headers: {
//           Authorization: `Bearer ${user}`,
//           Accept: "application/json",
//           "Content-Type": "application/json;charset=UTF-8",
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Credentials": "true",
//         },
//       })
//       .then((response) => {
//         console.log("hocam nediru bu response", response);
//       })
//       .catch((err) => {
//         const mute = err;
//       });
//   } catch (err) {
//     console.log("hatayı verdi3");

//     return false;
//     console.log("hatayı verdi4");
//   }
// }

export const authenticationService = {
  login,
  session,
  changePassword,
  getClaimCategories,
  getPermissions,
  addRole,
  deleteUserPermission,
  getRolesAll,
};
