import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_CHECKED_SUCCESS,
  LOGIN_CHECKED_FAIL,
} from "../action/types";
import { authenticationService } from "../../api/services/authentication.service";

import jwt_decode from "jwt-decode";

const token = localStorage.getItem("user");

// authenticationService.session();
// async function tokenExpired(){
//     const token = getLocalStorage();
//     var decoded = jwt_decode(token);
//     const timestamp  = decoded.exp*1000;
//     var date = new Date(timestamp);
//     console.log("datesssss",date)
//     if(date<=currentTime)
//     {
//        logout()
//        navigate("/login", { replace: true });

//     }
//   }

const initialState = {
  isLoggedIn: false,
  user: token || null,
  role: token ? jwt_decode(token).role : null,
  name: token ? jwt_decode(token).name : null,
  userId: token ? jwt_decode(token).userId : null,
  token: token ? token : null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        role: jwt_decode(payload.user).role,
        name: jwt_decode(payload.user).user,
        userId: jwt_decode(payload.user).id,
        token: payload.user,
      };
    case LOGIN_CHECKED_SUCCESS:
      return {
        isLoggedIn: true,
        user: token,
        role: jwt_decode(token).role,
        name: jwt_decode(token).user,
        userId: jwt_decode(token).id,
        token: token,
      };
    case LOGIN_CHECKED_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        role: null,
        name: null,
        userId: null,
        token: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        role: null,
        name: null,
        userId: null,
        token: null,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        role: null,
        name: null,
        userId: null,
      };
    default:
      return state;
  }
};
export { authReducer };
