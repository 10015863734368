import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideNotification } from "../../../../store/action/notificationAction";
/*Notification */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notificationSuccess = (prop) =>
  toast.success(prop, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notificationError = (prop) =>
  toast.error(prop, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

function Notification() {
  const { message, type } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (message !== "") {
      if (type === "success" || type === "true") {
        notificationSuccess(message);
      } else if (type === "error" || type === "false") {
        notificationError(message);
      }
      dispatch(hideNotification());
    }
  }, [message, type, dispatch]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      style={{ width: "500px" }}
    />
  );
}

export default Notification;
