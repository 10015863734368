//Customer add-edit
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
/* Material */
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox"; // Checkbox bileşeni eklendi
import FormControlLabel from "@mui/material/FormControlLabel"; // FormControlLabel bileşeni eklendi
import { Row, Button, TextInputTitle, Col } from "../Elements";
import { CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { Box, Autocomplete } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
/*Redux */
import { useDispatch, useSelector } from "react-redux";
/*Api*/
import { receiverSenderService } from "../../../../api/services/receiverSender.service";
import { shipmentService } from "../../../../api/services/shipment.service";
/*validation*/
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import MyDivBox from "views/components/common/myDivBox/MyDivBox";
/*My Actions */
import { showNotification } from "../../../../store/action/notificationAction";
import {
  CreateCustomer,
  UpdateCustomer,
} from "../../../../store/action/customerAction";
import { PatternFormat } from "react-number-format";
import {
  showLoader,
  hideLoader,
} from "../../../../store/action/upsideLineLoaderAction";

export default function CustomerForm({ setCustomerQuickAdd }) {
  const [edit, setEdit] = React.useState(false);
  const [showTcField, setShowTcField] = useState(false); // TC alanının görünüp görünmeyeceğini tutan durum

  const [countryCodeSelected, setCountryCodeSelected] = useState(1);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [district, setDistrict] = React.useState([]);
  const [province, setProvince] = React.useState([]);

  /*Hooks */
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();

  // TC kimlik numarasını kontrol eden fonksiyon
  const validateTc = (tc) => {
    // TC kimlik numarasının doğruluğunu kontrol etmek için uygun bir algoritma kullanın
    // Örneğin, 11 haneli olup olmadığını ve belirli bir formatı takip edip etmediğini kontrol edebilirsiniz
    return tc.length === 11 && !isNaN(tc);
  };

  /*validation */
  const validationSchema = Yup.object().shape({
    customerTcV: Yup.string()
      .required("Tc ismi boş bırakılamaz")
      .min(11, "Tc 11 haneli olmalı!")
      .test("is-number", "Tc numara olmalıdır", (value) => !isNaN(value))
      .max(11, "Tc 11 haneli olmalı!"),
    //  customerMailV: Yup.string().email().required("Alıcı mail boş bırakılamaz"),
    customerNameSurnameV: Yup.string().required("Alıcı ismi boş bırakılamaz"),
    customerAddressV: Yup.string().required("Alıcı adresi boş bırakılamaz"),
    customerProvinceV: Yup.string().required("Alıcı il boş bırakılamaz"),
    customerDistrictV: Yup.string().required("Alıcı ilçe boş bırakılamaz"),
    customerNeighborhoodV: Yup.string().required(
      "Alıcı mahalle boş bırakılamaz"
    ),
    customerAddress2V: Yup.string(),
    customerPhoneNumberV: Yup.string()
    .required("Müşteri telefon boş bırakılamaz"),
    /*  customerPhoneNumberV: Yup.string().required(
      "Müşteri telefon boş bırakılamaz"
    ), */
    /*   .test(
        "is-number",
        "Telefon numarası geçerli bir sayı olmalıdır",
        (value) => {
          if (value === undefined || value === null) return true; // Allow empty values
          const numericValue = Number(value);
          return !isNaN(numericValue);
        }
      ) */

    // .max(10, "Telefon numarası başında 0 yazılmadan 10 haneli olmalıdır")
    // .min(10, "Telefon numarası başında 0 yazılmadan 10 haneli olmalıdır"),
  });

  if (!showTcField) {
    delete validationSchema.fields.customerTcV;
    /*   validationSchema.fields.customerPhoneNumberV = Yup.string()
      .test(
        "is-number",
        "Telefon numarası geçerli bir sayı olmalıdır",
        (value) => {
          if (value === undefined || value === null) return true; // Allow empty values
          const numericValue = Number(value);
          return !isNaN(numericValue);
        }
      )
      .required("Müşteri telefon boş bırakılamaz")
      .max(11, "Telefon numarası 11 haneli olmalıdır")
      .min(11, "Telefon numarası 11 haneli olmalıdır"); */
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (edit) {
      updateCustomer(data);
      //setCustomerQuickAdd(false); //Ekle'ye basıldıktan sonra hızlı ekle menüsü tekrar kapatılsın istendi.
    } else {
      //console.log(data);
      createCustomer(data);
      //  setCustomerQuickAdd(false); //Ekle'ye basıldıktan sonra hızlı ekle menüsü tekrar kapatılsın istendi.
    }
  }; /*Forms values */

  const onPhoneInputChange = (values) => {
    //console.log(values); // Değeri konsola yazdır
    setValue("customerPhoneNumberV", values.value); // Doğru değeri form alanına atar
  };

  const createCustomer = async (data) => {
    await dispatch(showLoader());
    let defaultTc = data.customerTcV ? data.customerTcV : "11111111111";

    let defaultMail = data.customerMailV
      ? data.customerMailV
      : "default@doremuzik.com.tr";

    const customerObject = {
      tc: defaultTc,
      nameSurname: data.customerNameSurnameV,
      mail: defaultMail,
      countryCode: data.countryCode,
      phone: data.customerPhoneNumberV,
      address: data.customerAddressV,
      /* districtId: data.customerDistrictV.districtId,
      provinceId: data.customerProvinceV.provinceId, */
      /* districtName: customerDistrict,
      provinceName: customerProvince, */
      //countryCodeId: countryCodeSelected.toString(),
      districtName: data.customerDistrictV,
      provinceName: data.customerProvinceV,
      neighborhood: data.customerNeighborhoodV,
      address2: data.customerAddress2V || "",
    };
    //console.log("customerObject", customerObject);
    const tokenResponce = await dispatch(CreateCustomer(customerObject)); // Dispatch the CreateCustomer action with the token response

    if (tokenResponce && tokenResponce.success === true) {
      if (setCustomerQuickAdd) {
        //navigate("/shipmentAdd/direct");
        navigate(location.pathname);
        setEdit(false);
        //setCustomerQuickAdd(false); //Ekle'ye basıldıktan sonra hızlı ekle menüsü tekrar kapatılsın istendi. buraya eklenebilir
      } else {
        navigate("../customer");
        setEdit(false);
      }
    }

    await dispatch(hideLoader());
    //console.log(tokenResponce);
    await dispatch(
      showNotification(tokenResponce.message, "" + tokenResponce.success)
    );
    cleanFrom();
  };

  const updateCustomer = async (data) => {
    await dispatch(showLoader());

    /* let customerProvince = getValues("customerProvinceV");
    let customerDistrict = getValues("customerDistrictV"); */
    const customerObject = {
      id: id,
      tc: data.customerTcV,
      nameSurname: data.customerNameSurnameV,
      mail: data.customerMailV,
      phone: data.customerPhoneNumberV,
      address: data.customerAddressV,
      /* provinceId: customerProvince.provinceId,
      districtId: customerDistrict.districtId, */
      districtName: data.customerDistrictV,
      provinceName: data.customerProvinceV,
      neighborhood: data.customerNeighborhoodV,
      address2: data.customerAddress2V || "",
    };
    const tokenResponce = await dispatch(UpdateCustomer(customerObject)); // Dispatch the updateCustomer action with the token response
    if (tokenResponce && tokenResponce.success === true) {
      navigate("../customer");
      setEdit(false);
    }
    await dispatch(hideLoader());
    //console.log(tokenResponce);
    await dispatch(
      showNotification(tokenResponce.message, "" + tokenResponce.success)
    );
    cleanFrom();
  };

  const fetchCountryCode = async () => {
    // setLoading(true);
    const responce = await receiverSenderService.getCountryCode();
    if (responce) {
      setCountryCodeList(responce[0]);
      //setLoading(false);
    }
  };

  const fetchCustomerById = async (id) => {
    const responce = await receiverSenderService.getByIdCustomer(id);
   // console.log("responce", responce);
    if (responce) {
      setValue("customerTcV", responce[0].data.tc);
      setValue("customerNameSurnameV", responce[0].data.nameSurname);
      setValue("customerMailV", responce[0].data.email);
      setValue("customerPhoneNumberV", responce[0].data.phone);
      setValue("customerAddressV", responce[0].data.address);
      setValue("customerProvinceV", responce[0].data.province);
      setValue("customerDistrictV", responce[0].data.district);
      setValue("customerNeighborhoodV", responce[0].data.neighborhood);
      setValue("customerAddress2V", responce[0].data.address2);
    }
  };

  /* const fetchDistrict = async () => {
    const responce = await shipmentService.getDistrict();
    setDistrict(responce);
  };
  const fetchProvince = async () => {
    const responce = await shipmentService.getProvince(id);
    setProvince(responce);
  }; */
  const closeEdit = () => {
    cleanFrom();
    setEdit(false);
    navigate("/receiverSender/customer");
  };
  const cleanFrom = () => {
    setValue("customerTcV", "");
    setValue("customerNameSurnameV", "");
    setValue("customerMailV", "");
    setValue("customerPhoneNumberV", "");
    setValue("customerAddressV", "");
    setValue("customerProvinceV", "");
    setValue("customerDistrictV", "");
    setValue("customerNeighborhoodV", "");
    setValue("customerAddress2V", "");
  };
  useEffect(() => {
    setEdit(false);
    //fetchCountryCode();
    /*  fetchDistrict();
    fetchProvince(); */
    cleanFrom();
    if (id) {
      if (location.state !== null) {
        const { edit } = location.state;
        const { tableData } = location.state;
        tableData[0].tc === "11111111111"
          ? setShowTcField(false)
          : setShowTcField(true);

        // setShowTcField(edit);
        //    setValue("customerTcV", tableData[0].tc);
        setValue(
          "customerTcV",
          tableData[0].tc
          //  tableData[0].tc === "11111111111" ? "" : tableData[0].tc
        );
        setValue("customerNameSurnameV", tableData[0].nameSurname);
        setValue("customerMailV", tableData[0].email);
        setValue("customerPhoneNumberV", tableData[0].phone);
        setValue("customerAddressV", tableData[0].address);
        setValue("customerProvinceV", tableData[0].provinceName);
        setValue("customerDistrictV", tableData[0].districtName);
        setValue("customerNeighborhoodV", tableData[0].neighborhood);
        setValue("customerAddress2V", tableData[0].address2);

        setEdit(true);
      } else {
        setEdit(true);

        fetchCustomerById(id);
      }
    }
    clearErrors("customerTcV");
    clearErrors("customerNameSurnameV");
    clearErrors("customerMailV");
    clearErrors("customerPhoneNumberV");
    clearErrors("customerAddressV");
    clearErrors("customerAddress2V");
    clearErrors("customerProvinceV");
    clearErrors("customerDistrictV");
    clearErrors("customerNeighborhoodV");

    // setShowTcField(edit);
  }, [id, location, edit]);
  const phoneNumberValue = watch("customerPhoneNumberV") || "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        component={Grid}
        container
        spacing={0}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12}>
          <MyDivBox
            title={edit ? "Alıcı-Customer Düzenle" : "Alıcı-Customer Ekleme"}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{textAlign:"center"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTcField}
                    onChange={() => setShowTcField(!showTcField)}
                    color="primary"
                  />
                }
                label="TC Vatandaşı mı?"
                labelPlacement="start"
              />
            </Grid>
            {showTcField /* && state.tc !== "11111111111" */ && (
              <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
                <TextInputTitle>Tc </TextInputTitle>
                <TextField
                  fullWidth={true}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Ör: 13169208592"
                  {...register("customerTcV", { value: "" })}
                  error={errors.customerTcV ? true : false}
                  helperText={errors.customerTcV?.message}
                  //style={{ width: "100%", marginLeft: "10px" }}
                  sx={{ width: "98%", marginLeft: "10px" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>Ad Soyad</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Ör: Mehmet"
            {...register("customerNameSurnameV", { value: "" })}
            error={errors.customerNameSurnameV ? true : false}
            helperText={errors.customerNameSurnameV?.message}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>Mail</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            placeholder="Ör: mehmet@gmail.com"
            variant="outlined"
            {...register("customerMailV")}
            error={errors.customerMailV ? true : false}
            helperText={errors.customerMailV?.message}
          />
        </Grid>
       

        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <div
            style={{ width: "100%", marginTop: "25px", borderRadius: "20px" }}
          >
            <PhoneInput
              country="tr"
              value={phoneNumberValue}
              onChange={(phoneNumber) => {
                setValue("customerPhoneNumberV", phoneNumber || "");
              }}
              inputStyle={{ width: "100%", height: "50px" }}
            >
              aa
            </PhoneInput>
            {errors.customerPhoneNumberV && (
              <p style={{ color: "red", fontSize: "12px", paddingLeft: "10px" }}>
                {errors.customerPhoneNumberV.message}
              </p>
            )}
          </div>
        </Grid>



        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>İl</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Ör: Bursa"
            {...register("customerProvinceV")}
            error={errors.customerProvinceV ? true : false}
            helperText={errors.customerProvinceV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>İlçe</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            placeholder="Ör: Nilüfer"
            variant="outlined"
            {...register("customerDistrictV")}
            error={errors.customerDistrictV ? true : false}
            helperText={errors.customerDistrictV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>Mahalle</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            placeholder="Ör: Etiler Mah"
            variant="outlined"
            {...register("customerNeighborhoodV")}
            error={errors.customerNeighborhoodV ? true : false}
            helperText={errors.customerNeighborhoodV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>Adres</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            placeholder="Ör: Şahin Sok. No:28 YILDIZ Apt. Daire:12"
            multiline
            rows={4}
            variant="outlined"
            {...register("customerAddressV")}
            error={errors.customerAddressV ? true : false}
            helperText={errors.customerAddressV?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={6} sx={{ p: 2 }}>
          <TextInputTitle>Diğer Adres(isteğe bağlı)</TextInputTitle>
          <TextField
            fullWidth={true}
            id="outlined-basic"
            placeholder="Ör: Şahin Sok. No:28 YILDIZ Apt. Daire:12"
            multiline
            rows={4}
            variant="outlined"
            {...register("customerAddress2V")}
            error={errors.customerAddress2V ? true : false}
            helperText={errors.customerAddress2V?.message}
          />
        </Grid>

        {/*   <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInputTitle>Ad Soyad</TextInputTitle>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                variant="outlined"
                placeholder="Ör: Mehmet"
                {...register("customerNameSurnameV", { value: "" })}
                error={errors.customerNameSurnameV ? true : false}
                helperText={errors.customerNameSurnameV?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInputTitle>Mail</TextInputTitle>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                placeholder="Ör: mehmet@gmail.com"
                variant="outlined"
                {...register("customerMailV")}
                error={errors.customerMailV ? true : false}
                helperText={errors.customerMailV?.message}
              />
            </Grid>          

            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <PhoneInput
                  country="tr"
                  value={phoneNumberValue}
                  onChange={(phoneNumber) => {
                    setValue("customerPhoneNumberV", phoneNumber || ""); // Eğer phoneNumber undefined veya null ise, boş bir dizeyle değiştirin
                   // console.log(phoneNumber); // Değeri konsola yazdır
                  }}
                  inputStyle={{ width: "100%" }}

               
                >
                  aa
                </PhoneInput>
              </div>
            </Grid>
          </Grid>
        </Grid> */}

        {/*   <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInputTitle>İl</TextInputTitle>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                variant="outlined"
                placeholder="Ör: Bursa"
                {...register("customerProvinceV")}
                error={errors.customerProvinceV ? true : false}
                helperText={errors.customerProvinceV?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputTitle>İlçe</TextInputTitle>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                placeholder="Ör: Nilüfer"
                variant="outlined"
                {...register("customerDistrictV")}
                error={errors.customerDistrictV ? true : false}
                helperText={errors.customerDistrictV?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputTitle>Mahalle</TextInputTitle>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                placeholder="Ör: Etiler Mah"
                variant="outlined"
                {...register("customerNeighborhoodV")}
                error={errors.customerNeighborhoodV ? true : false}
                helperText={errors.customerNeighborhoodV?.message}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <TextInputTitle>Adres</TextInputTitle>
            <TextField
              fullWidth={true}
              id="outlined-basic"
              placeholder="Ör: Şahin Sok. No:28 YILDIZ Apt. Daire:12"
              multiline
              rows={4}
              variant="outlined"
              {...register("customerAddressV")}
              error={errors.customerAddressV ? true : false}
              helperText={errors.customerAddressV?.message}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <TextInputTitle>Diğer Adres(isteğe bağlı)</TextInputTitle>
            <TextField
              fullWidth={true}
              id="outlined-basic"
              placeholder="Ör: Şahin Sok. No:28 YILDIZ Apt. Daire:12"
              multiline
              rows={4}
              variant="outlined"
              {...register("customerAddress2V")}
              error={errors.customerAddress2V ? true : false}
              helperText={errors.customerAddress2V?.message}
            />
          </Grid>
        </Grid> */}
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Button style={{ margin: "10px" }} type="submit">
            {edit ? "Düzenle" : "Ekle"}
          </Button>
          <Button style={{ backgroundColor: "gray" }} onClick={closeEdit}>
            {edit ? " Düzenlemeyi kapat" : "Temizle"}

            {/* Burada Emin misiniz kutucuğu lazım */}
          </Button>
        </div>
      </div>
    </form>
  );
}
