import React from "react";
import Button from "@mui/material/Button";

function GeneralButton({ title, color, ...otherProp }) {
  return (
    <div>
      {" "}
      <Button
        {...otherProp}
        variant="contained"
        style={{ backgroundColor:  color , borderRadius: "10px", margin: "10px" }}
      >
        {title}
      </Button>
    </div>
  );
}

export default GeneralButton;
