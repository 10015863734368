import authHeader from "../auth-header";
import config from "./config";

const API_URL = config.basename + "Cars/";

async function getAll() {
  try {
    const carResponce = await fetch(API_URL + "getAll", authHeader("GET"));
    const allCar = await carResponce.json();
    if (!carResponce.ok) {
      return [allCar];
    } else {
      return [allCar];
    }
  } catch (err) {
    console.log("get car try yakaladı err", err);
  }
}

export const carService = {
  getAll,
};
