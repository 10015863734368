/*Shipment*/
const changeStatusToOnTheWay = "Hata! Sipariş Durumu Değiştirme Başarısız";
const create = "Hata! Sipariş Ekleme Başarısız";
const updateShipment = "Hata! Sipariş Güncelleme Başarısız";
const delivering = "Hata! Sipariş Teslim Başarısız";
const deleteShipment = "Hata! Sipariş Silme Başarısız";
const deleteUserPermission = "Hata! Role & Yetki Silme Başarısız";
const deleteDriverRegion = "Hata! Sürücüye ait Bölge Silme Başarısız";
const deleteStore = "Hata! Mağaza Silme Başarısız";
const deleteCustomer = "Hata! Müşteri Silme Başarısız";
const deleteWarehouse = "Hata! Depo Silme Başarısız";
/*SubShipment*/
const UpdateSubShipmentStatus = "Hata! Sipariş Durumu Değiştirme Başarısız";
/*ReceiverSender*/
//Customer
const createCustomer = "Hata! Müşteri Ekleme Başarısız";
const updateCustomer = "Hata! Müşteri Güncelleme Başarısız";
//Store
const createStore = "Hata! Mağaza Ekleme Başarısız";
const updateStore = "Hata! Mağaza Güncelleme Başarısız";
//Warehouse
const createWarehouse = "Hata! Depo Ekleme Başarısız";
const updateWarehouse = "Hata! Depo Güncelleme Başarısız";

export const apiMessages = {
  changeStatusToOnTheWay,
  create,
  updateShipment,
  delivering,
  deleteShipment,
  deleteUserPermission,
  deleteDriverRegion,
  UpdateSubShipmentStatus,
  createCustomer,
  updateCustomer,
  createStore,
  updateStore,
  deleteStore,
  deleteCustomer,
  deleteWarehouse,
  createWarehouse,
  updateWarehouse,
};
