import {
  ASSIGN_SHIPMENT_DIRECT,
  ASSIGN_SHIPMENT_DIRECT_MODAL_CLOSE,
  ASSIGN_SHIPMENT_DIRECT_MODAL_OPEN,
} from "../action/types";

const initialState = {
  shipment: "",
  car: "",
  driver: "",
  modalOpen: false,
};

function shipmentDirectAssignmentReducer(state = initialState, action) {
  switch (action.type) {
    case ASSIGN_SHIPMENT_DIRECT:
      return {
        ...state,
        shipment: action.payload.shipment,
        car: action.payload.shipment.car,
        driver: action.payload.shipment.driver,
      };
    case ASSIGN_SHIPMENT_DIRECT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
      };
    case ASSIGN_SHIPMENT_DIRECT_MODAL_CLOSE:
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
}

export { shipmentDirectAssignmentReducer };
