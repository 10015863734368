import { combineReducers } from "redux";

// reducer import
import { customizationReducer } from "./customizationReducer";
import { authReducer } from "./authReducer";
import { messageReducer } from "./messageReducer";
/*Mine */
import { notificationReducer } from "./notificationReducer";
import { upsideLineLoaderReducer } from "./upsideLineLoaderReducer";
import { productsReducer } from "./productsReducer";
import { customerReducer } from "./customerReducer";
import { shipmentDirectAssignmentReducer } from "./shipmentDirectAssignmentReducer";
import { shipmentPartialAssignmentReducer } from "./shipmentPartialAssignmentReducer";
import { shipmentPartialToDirectAssignmentReducer } from "./shipmentPartialToDirectAssignmentReducer";
import { shipmentReducer } from "./shipmentReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  message: messageReducer,
  notification: notificationReducer,
  product: productsReducer,
  customer: customerReducer,
  shipmentDirectAssignment: shipmentDirectAssignmentReducer,
  shipmentPartialAssignment: shipmentPartialAssignmentReducer,
  /*   shipmentPartialToDirectAssignmentReducer: shipmentPartialToDirectAssignmentReducer, */
  shipmentPartialToDirectAssignment: shipmentPartialToDirectAssignmentReducer,
  upsideLineLoader: upsideLineLoaderReducer,
  shipment: shipmentReducer,
});

export default reducer;
