import authHeader from "../auth-header";
import config from "./config";
import { apiMessages } from "../constant/messages";

const API_URL = config.basename + "ReceiverSender/";

async function getAllRecieverSender() {
  try {
    const receiverResponse = await fetch(
      API_URL + "getAllReceiver",
      authHeader("GET")
    );

    if (!receiverResponse.ok) {
      return receiverResponse.ok;
    } else {
      const responce = await receiverResponse.json();

      return [responce];
    }
  } catch (err) {
    console.log("get receiver try yakaladı err", err);
  }
}

/*Customeerrr------------------------------------------*/

async function getAllCustomer() {
  try {
    const receiverResponse = await fetch(
      API_URL + "getAllCustomer",
      authHeader("GET")
    );
    const responce = await receiverResponse.json();

    if (!receiverResponse.ok) {
      return [responce];
    } else {
      return [responce];
    }
  } catch (err) {
    console.log("customer.service get error end point geçersiz olabilir");
  }
}
/*--- */

/* async function getByIdCustomer(id) {
  try {
    const receiverResponse = await fetch(
      API_URL + "getByIdCustomer?id=" + id,
      authHeader("GET")
    );

    if (!receiverResponse.ok) {
      return receiverResponse.ok;
    } else {
      const responce = await receiverResponse.json();

      return [responce];
    }
  } catch (err) {
    console.log(
      "customer.service getByIdCustomer error end point geçersiz olabilir"
    );
  }
} */
/*--- */
const updateCustomer = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      id: data.id,
      tc: data.tc,
      nameSurname: data.nameSurname,
      email: data.mail,
      phone: data.phone,
      address: data.address,
      address2: data.address2,
      /*  provinceId: data.provinceId,
      districtId: data.districtId, */
      provinceName: data.provinceName,
      districtName: data.districtName,
      neighborhood: data.neighborhood,
    }),
  };
  /*   try {
    const tokenFetch = await fetch(API_URL + "updateCustomer", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.updateCustomer,
    };
  } */

  try {
    const tokenFetch = await fetch(API_URL + "updateCustomer", requestOptions);
    const response = await tokenFetch.json();

    if (response.success) {
      // Başarılı olduğunda yapılacak işlemler
      return response;
    } else {
      return {
        success: "error",
        message:
          response.message ||
          "Müşteri güncelleme işlemi sırasında bir hata oluştu",
      };
    }
  } catch (err) {
    console.error("There has been a problem with your fetch operation:", err);
    return {
      success: "error",
      message: apiMessages.updateCustomer,
    };
  }
};
/*--- */
const createCustomer = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      tc: data.tc,
      nameSurname: data.nameSurname,
      email: data.mail,
      phone: data.phone,
      address: data.address,
      address2: data.address2,
      /*  provinceId: data.provinceId,
      districtId: data.districtId, */
      provinceName: data.provinceName,
      districtName: data.districtName,
      neighborhood: data.neighborhood,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "addCustomer", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.createCustomer,
    };
  }
};

async function deleteCustomer(id) {
  const user = localStorage.getItem("user");

 const requestOptions = {
   withCredentials: true,
   method: "DELETE",
   headers: {
     Authorization: `Bearer ${user}`,
     Accept: "application/json",
     "Content-Type": "application/json;charset=UTF-8",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Credentials": "true",
   },
 };
 try {
   const tokenFetch = await fetch(API_URL + "deleteCustomer/" + id, requestOptions);
   const responce = await tokenFetch.json();
   return responce;
 } catch (err) {
   return {
     success: "error",
     message: apiMessages.deleteCustomer,
   };
 }
}



/*--- */
async function getCountryCode() { 
try {
  const Responce = await fetch(API_URL + "getCountryCode", authHeader("GET"));

  if (!Responce.ok) {
    return Responce.ok;
  } else {
    const result = await Responce.json();

    return [result];
  }
} catch (err) {
  console.log("get getDeliveryRange try yakaladı err", err);
}

}

/*--- */



/*Store------------------------------------------*/

async function getAllStore() {
  try {
    const receiverResponse = await fetch(
      API_URL + "getAllStore",
      authHeader("GET")
    );
    const responce = await receiverResponse.json();

    if (!receiverResponse.ok) {
      return [responce];
    } else {
      return [responce];
    }
  } catch (err) {
    console.log(
      "customer.service getAllStore error end point geçersiz olabilir"
    );
  }
}
/*--- */

async function getByIdStore(id) {
  try {
    const receiverResponse = await fetch(
      API_URL + "getByIdStore?id=" + id,
      authHeader("GET")
    );

    if (!receiverResponse.ok) {
      return receiverResponse.ok;
    } else {
      const responce = await receiverResponse.json();

      return [responce];
    }
  } catch (err) {
    console.log(
      "customer.service getByIdStore error end point geçersiz olabilir"
    );
  }
}

async function deleteStore(id) {
  const user = localStorage.getItem("user");

 const requestOptions = {
   withCredentials: true,
   method: "DELETE",
   headers: {
     Authorization: `Bearer ${user}`,
     Accept: "application/json",
     "Content-Type": "application/json;charset=UTF-8",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Credentials": "true",
   },
 };
 try {
   const tokenFetch = await fetch(API_URL + "deleteStore/" + id, requestOptions);
   const responce = await tokenFetch.json();
   return responce;
 } catch (err) {
   return {
     success: "error",
     message: apiMessages.deleteStore,
   };
 }
}


/*--- */
const updateStore = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      id: data.id,
      code: data.code,
      name: data.name,
      managerMail: data.managerMail,
      phone: data.phone,
      address: data.address,
      manager: data.manager,
      managerPhone: data.managerPhone,
      storeMail: data.storeMail,
      provinceName: data.provinceName,
      districtName: data.districtName,
      /*  districtId: data.districtId,
      provinceId: data.provinceId, */
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "updateStore", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.updateStore,
    };
  }
};
/*--- */
const createStore = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      code: data.code,
      name: data.name,
      managerMail: data.managerMail,
      phone: data.phone,
      address: data.address,
      manager: data.manager,
      managerPhone: data.managerPhone,
      storeMail: data.storeMail,
      provinceName: data.provinceName,
      districtName: data.districtName,
      /*  districtId: data.districtId,
      provinceId: data.provinceId, */
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "addStore", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.createStore,
    };
  }
};

/*warehouse------------------------------------------*/

async function getAllWarehouse() {
  try {
    const receiverResponse = await fetch(
      API_URL + "getAllWarehouse",
      authHeader("GET")
    );
    const responce = await receiverResponse.json();

    if (!receiverResponse.ok) {
      return [responce];
    } else {
      return [responce];
    }
  } catch (err) {
    console.log(
      "customer.service getAllWarehouse error end point geçersiz olabilir"
    );
  }
}
/*--- */

/* async function getByIdWarehouse(id) {
  try {
    const receiverResponse = await fetch(
      API_URL + "getByIdWarehouse?id=" + id,
      authHeader("GET")
    );

    if (!receiverResponse.ok) {
      return receiverResponse.ok;
    } else {
      const responce = await receiverResponse.json();

      return [responce];
    }
  } catch (err) {
    console.log(
      "customer.service getByIdWarehouse error end point geçersiz olabilir"
    );
  }
} */
/*--- */
const updateWarehouse = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      type:"Warehouse",
      id: data.id,
      code: data.code,
      name: data.name,
      managerMail: data.managerMail,
      phone: data.phone,
      address: data.address,
      manager: data.manager,
      managerPhone: data.managerPhone,
      warehouseMail: data.warehouseMail,
      provinceName: data.provinceName,
      districtName: data.districtName,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "updateWarehouse", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.updateWarehouse,
    };
  }
};
/*--- */
const createWarehouse = async (data) => {
  const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify({
      type:"Warehouse",
      code: data.code,
      name: data.name,
      managerMail: data.managerMail,
      phone: data.phone,
      address: data.address,
      manager: data.manager,
      managerPhone: data.managerPhone,
      warehouseMail: data.warehouseMail,
      provinceName: data.provinceName,
      districtName: data.districtName,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "addWarehouse", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    return {
      success: "error",
      message: apiMessages.createWarehouse,
    };
  }
};

async function deleteWarehouse(id) {
  const user = localStorage.getItem("user");

 const requestOptions = {
   withCredentials: true,
   method: "DELETE",
   headers: {
     Authorization: `Bearer ${user}`,
     Accept: "application/json",
     "Content-Type": "application/json;charset=UTF-8",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Credentials": "true",
   },
 };
 try {
   const tokenFetch = await fetch(API_URL + "deleteWarehouse/" + id, requestOptions);
   const responce = await tokenFetch.json();
   return responce;
 } catch (err) {
   return {
     success: "error",
     message: apiMessages.deleteWarehouse,
   };
 }
}


export const receiverSenderService = {
  getAllRecieverSender,
  createCustomer,
  getAllCustomer,
  updateCustomer,
  deleteCustomer,
  getCountryCode,
  // getByIdCustomer,
  createStore,
  getAllStore,
  updateStore,
  getByIdStore,
  deleteStore,
  createWarehouse,
  getAllWarehouse,
  updateWarehouse,
  deleteWarehouse,
  //getByIdWarehouse,
};
