import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

/*Metarial */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

/*My components*/
import DatePickerMUI from "../../datePicker/DatePickerMUI";
import QuickAdd from "../../QuickAdd/QuickAdd";
import MyDivBox from "../../../common/myDivBox/MyDivBox";

/*Api*/
import { productService } from "../../../../../api/services/product.service";
import { shipmentService } from "../../../../../api/services/shipment.service";
import { receiverSenderService } from "../../../../../api/services/receiverSender.service";
import { carService } from "../../../../../api/services/car.service";
import { deliveryService } from "../../../../../api/services/deliveryRange.service";
import { driverService } from "../../../../../api/services/driver.service";

/*Css */
import { ContactUsSelect, Option, Button, Row, Row2 } from "./Elements";

/*Notification */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*Icons */
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
/*validation*/
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import moment from "moment/moment";
import ReceiverQuickAdd from "views/components/receiversAndSenders/customer/CustomerQuickAdd";
import ModalPopUp from "views/components/common/modalPopUp/ModalPopUp";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../../../../store/action/notificationAction";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/action/upsideLineLoaderAction";
import GeneralButton from "views/components/common/myButton/GeneralButton";

export default function AddPartialShipment() {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const customerQuickAddRef = useRef(null);
  const token = window.localStorage.getItem("user");
  const [loading, setLoading] = useState(false);
  const [reflesh, setReflesh] = useState(false);
  //fetch request arrays
  const [shipmentStatuses, setShipmentStatuses] = useState([]);
  const [deliveryRangeList, setDeliveryRangeList] = useState([]);
  const [senderDtoList, setSenderDtoList] = useState([]);
  const [receiverDtoList, setReceiverDtoList] = useState([]);

  // form inputları
  const [productList, setProductList] = useState([
    {
      name: "",
      quantity: 1,
      outletChannelId: 1,
      outletChannelType: "",
      id: 0,
      outletChannelName: "",
      productCode: "",
    },
  ]);
  const [product, setProduct] = useState([""]); //fetch ilie çekilen yukarıdaki ise quantitiy eklemiş hali
  const [filteredProduct, setFilteredProduct] = useState([]);

  const [sendingDate, setSendingDate] = useState(new Date().toISOString());

  const [modals, setModals] = useState(false);
  const [selectedReceiverObj, setSelectedReceiverObj] = useState();

  const [driverList, setDriverList] = useState([]);

  // const [orderNumber, setOrderNumber] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [customerQuickAdd, setCustomerQuickAdd] = React.useState(false); //hızlı ekleme divini aktifleştiriyor

  const [deliveryCatalogSelected, setDeliveryCatalogSelected] = useState(1);
  const [deliveryRangeSelected, setDeliveryRangeSelected] = useState(1);
  const [salesChannel, setSalesChannel] = useState([]);

  const refresh = useSelector((state) => state.customer.refresh);
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchProduct();
    fetchReceiverSender();
   // fetchDriverList();
    setCount(count + 1);
    fetchSalesChannel();
    fetchDeliveryRange();
    fetchShipmentStatuses();
    if (customerQuickAdd) {
      scrollToCustomerQuickAdd();
    }
  }, [reflesh, refresh]);
  /*validation */
  const validationSchema = Yup.object().shape({
    orderNumberV: Yup.string().required("sipariş numarası girilmeli"),
    salesChannelV: Yup.string().required("Satış kanalı seçilmedi"),
    noteV: Yup.string().nullable(),
    gatheringPlaceV: Yup.string().required("Toplanma Yeri Seçilmedi"),
    products: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Ürün seçilmedi"),
        outlet: Yup.string(),
      })
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    addShipment();
  };

  /*functions */
  //multipple text input handle
  const handleQuantityChange = (e, index) => {
    const { name, value } = e.target;
    if (value <= 0 || value > 50) {
      alert("adet sayısı 1-50 arasında olmalıdır");
    } else {
      const list = [...productList];
      list[index][name] = value;
      setProductList(list);
    }
  };
  const handleLabelChange = (name, index, value) => {
    const list = [...productList];
    list[index][name] = value;
    setProductList(list);
  };
  const handleServiceRemove = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };
  const handleServiceAdd = () => {
    setProductList([
      ...productList,
      {
        id: "",
        quantity: 1,
        name: "",
        outletChannelId: 0,
        outletChannelType: "",
        outletChannelName: "",
      },
    ]);
  };
  function searchProducts(keyword) {
    if (!keyword) {
      setFilteredProduct([]);
      return;
    }

    const result = product.filter((product) => {
      const name = product.name.toLowerCase();
      const productCode = product.productCode.toLowerCase();
      return name.includes(keyword) || productCode.includes(keyword);
    });
    setFilteredProduct(result);
  }

  /*api-get request */
/*   const fetchDriverList = async () => {
    const objresponce = await driverService.getAll();
    setDriverList(objresponce[0]);
  }; */
  const fetchProduct = async () => {
    const user = token;
    setLoading(true);
    const responce = await productService.getProduct(user);
    if (responce) {
      setProduct(responce);
      setLoading(false);
    }
  };

  const fetchDeliveryRange = async () => {
    setLoading(true);
    const responce = await deliveryService.getDeliveryRange();
    if (responce) {
      setDeliveryRangeList(responce[0]);
      setLoading(false);
    }
  };
  const fetchShipmentStatuses = async () => {
    setLoading(true);
    const responce = await shipmentService.getShipmentStatuses();
    if (responce) {
      setShipmentStatuses(responce);
      setLoading(false);
    }
  };

  const fetchReceiverSender = async () => {
    setLoading(true);
    const responce = await receiverSenderService.getAllRecieverSender();

    if (responce) {
      setSenderDtoList([
        ...responce[0].warehouseListDto,
        ...responce[0].storeListDto,
      ]);
      setReceiverDtoList([
        //...responce[0].customerListDto,
        ...responce[0].warehouseListDto,
        // ...responce[0].storeListDto,
      ]);
      setLoading(false);
    }
  };
  const fetchSalesChannel = async () => {
    const responce = await shipmentService.getSalesChannel();
    setSalesChannel(responce);
  };

  const addShipment = async () => {
    await dispatch(showLoader());

    let orderV = getValues("orderNumberV");
    let noteV = getValues("noteV");

    let filteredList = productList.filter(
      (element) => element.id !== "" && element.id !== null
    );
    const tokenResponce = await shipmentService.addShipmentPartial({
      gatheringPlaceId: selectedReceiverObj.id,
      gatheringPlaceType: selectedReceiverObj.type,
      productObject: filteredList,
      deliveryRangeId: deliveryRangeSelected,
      orderNumber: orderV,
      dateOfSale: sendingDate,
      SalesChannel: getValues("salesChannelV"),
      note: noteV,
    });
    await dispatch(hideLoader());

    await dispatch(
      showNotification(tokenResponce.message, "" + tokenResponce.success)
    );

    if (tokenResponce && tokenResponce.success === true) {
      setTimeout(function () {
        navigate("/shipments");
      }, 2000);
    }
  };

  /*Scroll */

  const scrollToCustomerQuickAdd = () => {
    scroller.scrollTo("customerQuickAdd", {
      duration: 500,
      smooth: true,
      offset: -50, // Optional offset to adjust the scroll position
    });
  };

  return (
    <>
      <Row>
        <div
          style={{
            margin: "0px",
            padding: "20px",
            borderBottom: "1px solid #ebedf2",
          }}
        >
          Sevkiyat-Ürün Bilgisi
        </div>

        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "100%", marginRight: "20px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="productNameV"
              label="Sipariş Numarası"
              fullWidth
              {...register("orderNumberV")}
              error={errors.orderNumberV ? true : false}
              helperText={errors.orderNumberV?.message}
            />
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
            <Autocomplete
              disablePortal
              disableClearable
              // value={province || null}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                // setValue("salesChannelV", newValue.id);
              }}
              id="provinceId"
              options={salesChannel.map((option, index) => ({
                id: option.salesChannelId,
                label: option.name,
              }))}
              renderInput={(params) => (
                <TextField
                  label="Satış Kanalı"
                  {...params}
                  {...register("salesChannelV")}
                  error={errors.salesChannelV ? true : false}
                  helperText={errors.salesChannelV?.message}
                />
              )}
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <DatePickerMUI
              value={sendingDate}
              setValue={setSendingDate}
              title={"Satış Tarihi"}
            />
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="shipmentDeliveryDateInput">Süre</InputLabel>
              <Select
                labelId="shipmentDeliveryDateLabelId"
                id="shipmentDeliveryDateId"
                defaultValue={1}
                label="Sure"
                onChange={(e) => {
                  setDeliveryRangeSelected(e.target.value);
                }}
              >
                {deliveryRangeList.map((p, index) => (
                  <MenuItem key={index} value={p.id}>
                    {p.deliveryRangeName}
                  </MenuItem>
                ))}
              </Select>

              <p
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              ></p>
            </FormControl>
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="shipmentDeliveryDateInput">Durum</InputLabel>
              <Select
                labelId="shipmentDeliveryDateLabelId"
                id="shipmentDeliveryDateId"
                defaultValue={1}
                label="Sure"
                onChange={(e) => setDeliveryCatalogSelected(e.target.value)}
              >
                {shipmentStatuses.map((item, index) => {
                  if (index < 1) {
                    return (
                      <MenuItem key={index} value={item.shipmentStatuId}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>

              <p
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              ></p>
            </FormControl>
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
            <Autocomplete
              disablePortal
              value={selectedReceiverObj || null}
              groupBy={(option) => {
                if (option.type === "Warehouse") return "Depo";
                else return "Mağaza";
              }}
              getOptionLabel={(option) =>
                option.name ? `${option.name} ` : ""
              }
              isOptionEqualToValue={(option, value) =>
                option.id === value.id ||
                (option.id === value.id && option.name === value.name)
              }
              onChange={(event, newValue) => {
                setSelectedReceiverObj(newValue);
                // setSenderId(newValue.id);
                // setSenderAddress(newValue.address);
                // setSenderName(newValue.label);
              }}
              id="combo-box-demo"
              options={receiverDtoList.map((option, index) => ({
                id: option.id,
                label: option.name,
                address: option.address,
                name: option.name,
                lastName: option.lastName,
                type: option.type,
                tc: option.tc,
              }))}
              renderGroup={(params, index) => (
                <li key={`${params.key}-${params.group}-${index}-li`}>
                  {params.group}
                  <ul key={`${params.key}-${params.group}-ul`}>
                    {params.children}
                  </ul>
                </li>
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  key={`${option.id}-${option.type}-${option.label}`}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.type === "Customer" ? (
                    <PersonIcon sx={{ height: "14" }} />
                  ) : option.type === "Warehouse" ? (
                    <LocalShippingIcon sx={{ height: "14" }} />
                  ) : option.type === "Store" ? (
                    <StorefrontIcon sx={{ height: "14" }} />
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;
                  {option.label}&nbsp;{option.lastName}&nbsp;-&nbsp;
                  {option.tc}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("gatheringPlaceV")}
                  error={errors.gatheringPlaceV ? true : false}
                  helperText={errors.gatheringPlaceV?.message}
                  label="Alıcı Depo Seçiniz..."
                />
              )}
            />
          </div>
        </div>
      </Row>
      <Row>
        <MyDivBox title="Ürün Bilgileri" style={{ marginTop: "20px" }}>
          {/* <div style={{width:'150px'}}><QuickAddProduct setReflesh={setReflesh}/></div> */}

          <div
            style={{
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                textAlign: "center",
                marginBottom: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div>
                <GeneralButton
                  onClick={handleServiceAdd}
                  title={"Ürün Ekle"}
                  color={"purple"}
                ></GeneralButton>
              </div>
            </div>
            <div className="form-field" style={{ width: "100%" }}>
              {productList.map((p, index) => (
                <div
                  key={index}
                  className="services"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    key={index + 1}
                    className="first-division"
                    style={{ width: "100%", marginRight: "20px" }}
                  >
                    <Autocomplete
                      disableClearable
                      options={filteredProduct}
                      noOptionsText={
                        "Ürün Stok Kodu... (Üstü çizili ürünler şu an stokta gözükmemektedir)"
                      }
                      getOptionLabel={(product) =>
                        `${product.productCode}  -  ${product.name}`
                      }
                      onChange={(e, newValue) => {
                        handleLabelChange(
                          "productCode",
                          index,
                          newValue.productCode
                        );

                        handleLabelChange("name", index, newValue.label);
                        handleLabelChange("id", index, newValue.id);
                      }}
                      onInputChange={(event) => {
                        const inputValue =
                          typeof event.target.value === "string"
                            ? event.target.value.trim()
                            : "";
                        if (event.target.value.length > 0) {
                          setTimeout(() => {
                            searchProducts(inputValue);
                          }, 1000);
                        } else {
                          setFilteredProduct([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...register(`products.${index}.name`)}
                          error={errors.products?.[index]?.name ? true : false}
                          helperText={errors.products?.[index]?.name?.message}
                          {...params}
                          variant="outlined"
                          label={index + 1 + ". Ürün"}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <Autocomplete
                      disableClearable
                      key={index + 2}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      options={senderDtoList.map((option) => ({
                        id: option.id,
                        label: option.name,
                        type: option.type,
                      }))}
                      value={p.outletChannelName || ""}
                      disablePortal
                      onChange={(e, newValue) => {
                        handleLabelChange(
                          "outletChannelId",
                          index,
                          newValue.id
                        );
                        handleLabelChange(
                          "outletChannelType",
                          index,
                          newValue.type
                        );
                        handleLabelChange(
                          "outletChannelName",
                          index,
                          newValue.label
                        );
                      }}
                      groupBy={(option) => {
                        if (option.type === "Warehouse") return "Depo";
                        else return "Mağaza";
                      }}
                      renderOption={(props, option, index) => (
                        <Box
                          key={index}
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.label}&nbsp;&nbsp;
                          {option.type === "Mağaza" ? (
                            <StorefrontIcon sx={{ height: "14" }} />
                          ) : option.type === "Depo" ? (
                            <LocalShippingIcon sx={{ height: "14" }} />
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...register(`products.${index}.outlet`)}
                          error={
                            errors.products?.[index]?.outlet ? true : false
                          }
                          helperText={errors.products?.[index]?.outlet?.message}
                          {...params}
                          label="Çıkış Yeri "
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>

                  <div style={{ width: "30%", marginRight: "20px" }}>
                    <TextField
                      style={{}}
                      name="quantity"
                      inputProps={{ min: "1", max: "50", step: "1" }}
                      value={p.quantity}
                      id="outlined-basic"
                      label="Adet"
                      type="Number"
                      variant="outlined"
                      onChange={(e) => handleQuantityChange(e, index)}
                    />
                  </div>

                  {/*  <div style={{ width: "10%", marginRight: "20px" }}>
                    {productList.length - 1 === index && (
                      <button
                        type="button"
                        onClick={handleServiceAdd}
                        className="add-btn"
                      >
                        <span>
                          <AddCircleOutlineSharpIcon fontSize="small" />
                        </span>
                      </button>
                    )}
                  </div> */}
                  <div className="second-division">
                    {productList.length !== 1 && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleServiceRemove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      /* <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>Kaldır</span>
                      </button> */
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MyDivBox>
      </Row>
      {/* 2.part */}

      <div className="split" style={{ padding: "10px" }}></div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <TextField
          id="outlined-multiline-flexible"
          label="Sevkiyat Notları..."
          fullWidth={true}
          {...register("noteV")}
        />
      </div>
      <div className="split" style={{ padding: "10px" }}></div>
      {/* hızlı ekle kısımları */}
      <div id="section2">
        {customerQuickAdd ? (
          <div
            style={{ border: "3px gray dotted", margin: "0px", padding: "0px" }}
            id="customerQuickAdd"
            name="test1"
            ref={customerQuickAddRef}
          >
            <ReceiverQuickAdd
              setCustomerQuickAdd={setCustomerQuickAdd}
              setReflesh={setReflesh}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {/* 4.part */}
      <Row>
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <GeneralButton
            title={"Sevkiyatı Ekle"}
            color={"purple"}
            onClick={handleSubmit(onSubmit)}
          ></GeneralButton>
          <GeneralButton
            title={"İptal"}
            color={"red"}
            onClick={() => navigate(-1)}
          ></GeneralButton>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ width: "500px" }}
        />
      </Row>
    </>
  );
}
