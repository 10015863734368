import React from "react";
import { CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import MyDivBox from "views/components/common/myDivBox/MyDivBox";
import styled from "styled-components";

export const Row = styled.div`
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;

  .remove-btn {
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    color: red;
    border: 1px solid red;
  }
  .add-btn {
    background-color: #662494;
    outline: none;
    margin: 0px;
    padding: 2px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    color: white;
    border: 1px solid #662494;
  }
`;
export const Option = styled.option`
  padding: 10px;
`;
export const Button = styled.button`
  border-radius: 50px;
  white-space: nowrap;
  padding: 10px 22px;
  background-color: #662484;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;

export default function PickUpPackage() {
  return (
    <>
      <Box
        component={Grid}
        container
        spacing={0}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12}>
          <MyDivBox title={`Sipariş Teslim`} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ p: 2 }}>
          {/* <TextInputTitle>Alan Kişi </TextInputTitle> */}
          <TextField
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Ahmet"
            // {...register("handDeliveryPersonV", { value: "" })}
            // error={errors.handDeliveryPersonV ? true : false}
            // helperText={errors.handDeliveryPersonV?.message}
          />
        </Grid>
      </Box>

      <Row>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Button style={{ margin: "10px" }}>Sevkiyat Düzeni oluştur</Button>

          <Button style={{ backgroundColor: "gray" }}>Sıfırla</Button>
        </div>
      </Row>
    </>
  );
}
