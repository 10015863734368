import styled from "styled-components";

export const Container = styled.div`
  display: block; /* Remove bullet points; allow greater control of positioning */
  padding: 0; /* Override defaults for lists */
  margin: 0; /* Override defaults for lists */
  width: 100%;
`;
export const Header = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Table = styled.div`
  display: block; /* Remove bullet points; allow greater control of positioning */
  padding: 0; /* Override defaults for lists */
  margin: 0; /* Override defaults for lists */
  width: 100%;
`;
export const DeliveryInfoSection = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ReceiverSection = styled.div`
  width: 100%;
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 10px;
`;
export const SenderSection = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bbb;
  text-align: center;
`;
export const ProductContent = styled.div`
  border-bottom: 1px solid #bbb;

  width: 100%;
`;
export const TableTitle = styled.div`
  width: 100%;
  padding: 5px;
`;
