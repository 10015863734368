const orderTaken = 1;
const onTheWay = 2;
const delivered = 3;
const notDelivered = 4;

export const status = {
  orderTaken,
  onTheWay,
  delivered,
  notDelivered,
};
