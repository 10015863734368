export function showNotification(message, type) {
    return {
      type: 'SHOW_NOTIFICATION',
      payload: { message, type },
    };
  }
  
  export function hideNotification() {
    return {
      type: 'HIDE_NOTIFICATION',
    };
  }