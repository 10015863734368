import styled from "styled-components";

export const Container = styled.div`
cursor: pointer;
margin-left: 10px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  background-color: blue;
  align-items: center;
  text-align: center;
  background-color: #662484;
  color: white;
  font-size: 12px;
  padding-block: 5px;
  padding-inline: 8px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #951a81;
    color: white;
  }
`;
