import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const Profile = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Grid container spacing={0} justify="space-betwee">
        <Grid
          item
          xs={12}
          sm={3}
          md={1}
          alignSelf="center"
          alignContent={"center"}
          textAlign="center"
        >
          <img
            src="https://img.icons8.com/bubbles/100/000000/user.png"
            class="img-radius"
            alt="User-Profile-Image"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={8}>
          <div
            className="Phone"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ul style={{ paddingLeft: "0px", marginLeft: "0px" }}>
              <li style={{ listStyle: "none" }}>
                <p style={{ fontSize: "20px" }}>Sürücü</p>
              </li>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>Adam</p>
              <li style={{ listStyle: "none" }}></li>
              <li style={{ listStyle: "none" }}>34 Hty 50</li>
            </ul>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          alignSelf="center"
          alignContent={"center"}
          textAlign="center"
        >
          <div
            className="Phone"
            style={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "purple",
                display: "inline-flex",
                padding: "20px",
                borderRadius: "10px",
                textAlign: "start",
                alignItems: "center",
              }}
            >
              <div>X1 </div>
              <div
                style={{
                  marginLeft: "10px",
                  width: "100%",
                  color: "white",
                }}
              >
                <div>Telefon</div>
                <div> 5066882226</div>
              </div>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
