import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

/*Metarial */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

/*My components*/
import DatePickerMUI from "../../datePicker/DatePickerMUI";
import QuickAdd from "../../QuickAdd/QuickAdd";
import MyDivBox from "../../../common/myDivBox/MyDivBox";
import NativeSelect from "@mui/material/NativeSelect";
import Loader from "../../../../../ui-component/Loader";

/*Api*/
import { productService } from "../../../../../api/services/product.service";
import { shipmentService } from "../../../../../api/services/shipment.service";
import { receiverSenderService } from "../../../../../api/services/receiverSender.service";
import { carService } from "../../../../../api/services/car.service";
import { deliveryService } from "../../../../../api/services/deliveryRange.service";

/*Css */
import {
  ContactUsSelect,
  Option,
  Row,
  Row2,
} from "./Elements"; /* ,Button ı silip meteralUı button ını kullandım */
/*Notification */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*Icons */
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
/*validation*/
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import moment from "moment/moment";
import ReceiverQuickAdd from "views/components/receiversAndSenders/customer/CustomerQuickAdd";
import ModalPopUp from "views/components/common/modalPopUp/ModalPopUp";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../../../../store/action/notificationAction";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/action/upsideLineLoaderAction";
import { Container } from "../../QuickAdd/Elements";
import { borderRadius } from "@mui/system";
import { purple } from "@mui/material/colors";
import GeneralButton from "views/components/common/myButton/GeneralButton";

const switchLabel = { inputProps: { "aria-label": "Switch demo" } };

export default function AddDirectShipment() {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [switchCheck, setSwitchCheck] = useState(false);
  const [switchCheckProduct, setSwitchCheckProduct] = useState(false);

  const [deliveryCatalogId, setDeliveryCatalogId] = React.useState(""); //seçilen status(yolda,dağıtımda..) kısmının karşılığı Id
  const token = window.localStorage.getItem("user");
  const [loading, setLoading] = useState(false);
  const [reflesh, setReflesh] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  //fetch request arrays
  const [receiverList, setReceiverList] = useState([]);
  const [carsList, setCarsList] = React.useState([]);
  const [shipmentStatuses, setShipmentStatuses] = useState([]);
  const [deliveryRangeList, setDeliveryRangeList] = useState([]);
  const [distributionWarehouseList, setDistributionWarehouseList] = useState([]);
  const [senderDtoList, setSenderDtoList] = useState([]);
  const [receiverDtoList, setReceiverDtoList] = useState([]);
  // form inputları
  const [productList, setProductList] = useState([
    {
      name: "",
      quantity: 1,
      outletChannelId: 1,
      outletChannelType: "",
      id: 0,
      outletChannelName: "",
      productCode: "",
    },
  ]);
  const [productListAddition, setProductListAddition] = useState([
    {
      name: "",
      quantity: 1,
      outletChannelId: 1,
      outletChannelType: "",
      id: 0,
      outletChannelName: "",
      productCode: "",
    },
  ]);
  const [product, setProduct] = useState([""]); //fetch ilie çekilen yukarıdaki ise quantitiy eklemiş hali
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [senderList, setSenderList] = useState([]);
  const [senderId, setSenderId] = useState();
  const [sendingDate, setSendingDate] = useState(new Date().toISOString());
  const [sendingDispatchDate, setSendingDispatchDate] = useState(
    new Date().toISOString()
  );
  const [modals, setModals] = useState(false);
  const [selectedReceiverObj, setSelectedReceiverObj] = useState();
  // console.log("selectedReceiverObj", selectedReceiverObj);
  // const [orderNumber, setOrderNumber] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [customerQuickAdd, setCustomerQuickAdd] = React.useState(false); //hızlı ekleme divini aktifleştiriyor
  const customerQuickAddRef = React.useRef(null);
  const [deliveryCatalogSelected, setDeliveryCatalogSelected] = useState(1);
  const [deliveryRangeSelected, setDeliveryRangeSelected] = useState(1);
  const [distributionWarehouseSelected, setDistributionWarehouseSelected] = useState(1); // Başlangıç değeri olarak boş string kullanın
  const [outletWarehouseId, setOutletWarehouseId] = useState(1);
  const [salesChannel, setSalesChannel] = useState([]);
  const [outletWarehouse, setOutletWarehouse] = useState([]);


  const [outletPlace, setOutletPlace] = useState([
    {
      id: 0,
      type: "",
    },
  ]);

  
  const refresh = useSelector((state) => state.customer.refresh);
  /*  const aa = (index) => {
    const updatedProductList = [...productList];
    updatedProductList.splice(index, 1);
    console.log("ilk", productList);
    console.log("son", updatedProductList);
    setProductList(updatedProductList);
  }; */
  useEffect(() => {
    fetchProduct();
    fetchReceiverSender();
    //fetchCar();
    fetchSalesChannel();
    fetchOutletWarehouse();
    fetchDeliveryRange();
    fetchDistributionWarehouse();
    fetchShipmentStatuses();
    if (customerQuickAdd) {
      scrollToCustomerQuickAdd();
    }
    setAutocompleteLoading(true);
  }, [reflesh, refresh, customerQuickAdd]);
  //productList
  /*validation */

  const validationSchema = Yup.object()
    .shape({
      orderNumberV: Yup.string().required("sipariş numarası girilmeli"),
      salesChannelV: Yup.string().required("Satış kanalı seçilmedi"),
     // distributionWarehouse: Yup.string().required("Çıkış Deposu seçilmedi"),
      receiverV: Yup.string().required("Alıcı seçilmedi"),
      senderV: Yup.string().nullable(),
      noteV: Yup.string().nullable(),
     // outletPlaceV: Yup.string().required("Çıkış Yeri Seçilmedi"),
      products: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Ürün seçilmedi"),
          outlet: Yup.string(),
        })
      ),
    })
    .nullable();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    addShipment();
  };

  /*functions */

  //multipple text input handle
  const updateProductQuantity = (e, index) => {
    const { name, value } = e.target;
    if (value <= 0 || value > 50) {
      alert("adet sayısı 1-50 arasında olmalıdır");
    } else {
      const list = [...productList];
      list[index][name] = value;
      setProductList(list);
    }
  };
  const updateProductLabel = (name, index, value) => {
    const list = [...productList];
    list[index][name] = value;
    setProductList(list);
  };
  const removeProductFromList = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
    /*  updateProductLabel(
      "productCode",
      index,
      newValue.productCode
    ); */
  };
  const addNewProductToList = () => {
    setProductList([
      ...productList,
      {
        id: "",
        quantity: 1,
        name: "",
        outletChannelId: 0,
        outletChannelType: "",
        outletChannelName: "",
      },
    ]);
  };
  const addNewProductAdditionToList = () => {
    setProductListAddition([
      ...productListAddition,
      {
        id: "",
        quantity: 1,
        name: "",
        outletChannelId: 0,
        outletChannelType: "",
        outletChannelName: "",
      },
    ]);
  };

  const updateProductAdditionQuantity = (e, index) => {
    const { name, value } = e.target;
    if (value <= 0 || value > 50) {
      alert("adet sayısı 1-50 arasında olmalıdır");
    } else {
      const list = [...productListAddition];
      list[index][name] = value;
      setProductListAddition(list);
    }
  };
  const updateProductAdditionLabel = (name, index, value) => {
    const list = [...productListAddition];
    list[index][name] = value;
    setProductListAddition(list);
  };
  const removeProductAdditionFromList = (index) => {
    const list = [...productListAddition];
    list.splice(index, 1);
    setProductListAddition(list);
  };

  function searchProducts(keyword) {
    if (!keyword) {
      setFilteredProduct([]);
      return;
    }

    const result = product.filter((product) => {
      const name = product.name.toLowerCase();
      const productCode = product.productCode.toLowerCase();
      return name.includes(keyword) || productCode.includes(keyword);
    });
    setFilteredProduct(result);
  }

  /*api-get request */
  const fetchProduct = async () => {
    const user = token;
    setLoading(true);
    const responce = await productService.getProduct(user);
    if (responce) {
      setProduct(responce);
      setLoading(false);
    }
  };

  /*  const fetchCar = async () => {
    const user = token;
    setLoading(true);
    const responce = await carService.getAll(user);
    if (responce) {
      setCarsList(responce[0]);
      setLoading(false);
    }
  }; */

  const fetchDeliveryRange = async () => {
    setLoading(true);
    const responce = await deliveryService.getDeliveryRange();
    if (responce) {
      setDeliveryRangeList(responce[0]);
      setLoading(false);
      console.log(responce[0]);
    }
  };
  const fetchDistributionWarehouse = async () => {
    setLoading(true);
    const responce = await receiverSenderService.getAllWarehouse();
    if (responce) {
      setDistributionWarehouseList(responce[0]);
      setLoading(false);
      console.log(responce[0]);
      setDistributionWarehouseSelected(responce[0][2].id); // İlk öğenin ID'sini kullan
    }
  };

  
  const fetchShipmentStatuses = async () => {
    setLoading(true);
    const responce = await shipmentService.getShipmentStatuses();
    //console.log("gelen ne", responce);
    if (responce) {
      setShipmentStatuses(responce);
      setLoading(false);
    }
  };
  const fetchReceiverSender = async () => {
    setLoading(true);
    const responce = await receiverSenderService.getAllRecieverSender();
//console.log(responce);
    if (responce) {
      setSenderDtoList([
        ...responce[0].warehouseListDto,
        ...responce[0].storeListDto,
      ]);
      setReceiverDtoList([
        ...responce[0].customerListDto,
        // ...responce[0].warehouseListDto,
        // ...responce[0].storeListDto,
      ]);
      setLoading(false);
    }
  };
  const fetchSalesChannel = async () => {
    const responce = await shipmentService.getSalesChannel();
    setSalesChannel(responce);
   // console.log(responce);
  };
  const fetchOutletWarehouse = async () => {
    const responce = await receiverSenderService.getAllRecieverSender();
    setOutletWarehouse(responce[0].warehouseListDto);
   // console.log(responce[0].warehouseListDto);
  };
  
  const addShipment = async () => {
    await dispatch(showLoader());
    // setInProcess(true);
    let orderV = getValues("orderNumberV");
    let noteV = getValues("noteV");

    let filteredList = productList.filter(
      (element) => element.id !== "" && element.id !== null
    );
    let filteredListAddition = productListAddition.filter(
      (element) => element.id !== "" && element.id !== null
    );
    productList.forEach((p, index) => {
      p.outletChannelId = distributionWarehouseSelected;
      p.outletChannelType = "Warehouse";
    });

    const combineProductLists = [
      ...productList,
      ...(filteredListAddition.length > 0 ? filteredListAddition : []),
    ];
    const validProductLists = combineProductLists.filter(
      (product) => product.productCode
    );

    const tokenResponce = await shipmentService.addShipmentDirect({
      receiverId: selectedReceiverObj.id ? selectedReceiverObj.id : 1,
      receiverType: selectedReceiverObj.type ? selectedReceiverObj.type : "-",
      gatheringPlaceId: outletWarehouseId, 
      gatheringPlaceType: "Warehouse",
      productObject: validProductLists,
      deliveryRangeId: deliveryRangeSelected,
      distributionWarehouseId: distributionWarehouseSelected,
      deliveryCatalogId: deliveryCatalogSelected.toString(),
      
      SalesChannel: getValues("salesChannelV"),
     // OutletWarehouse: getValues("outletWarehouseV"),

     orderNumber: orderV,
      dateOfSale: sendingDate,
      dateOfDispatch: sendingDispatchDate,
      note: noteV,
     // outletPlaceType: outletPlace[0].type,
     // outletPlaceId: outletPlace[0].id,
     
    });
    // setInProcess(false);
    await dispatch(hideLoader());
    await dispatch(
      showNotification(tokenResponce.message, "" + tokenResponce.success)
    );

    if (tokenResponce && tokenResponce.success === true) {
      setTimeout(function () {
        navigate("/shipments");
      }, 2000);
    }
  };

  /*Scroll */

  const scrollToCustomerQuickAdd = () => {
    /* if (customerQuickAddRef.current) {
      customerQuickAddRef.current.scrollIntoView({
      
        duration: 500,
      smooth: true,
      offset: -50,
      });
    } */

    scroller.scrollTo("customerQuickAdd", {
      duration: 500,
      smooth: true,
      offset: -50,
      // Optional offset to adjust the scroll position
    });
  };

  return (
    <>
      <Row>
        <div
          style={{
            margin: "0px",
            padding: "20px",
            borderBottom: "1px solid #ebedf2",
          }}
        >
          Sevkiyat-Ürün Bilgisi
        </div>

        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "100%", marginRight: "20px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="productNameV"
              label="Sipariş Numarası"
              fullWidth
              {...register("orderNumberV")}
              error={errors.orderNumberV ? true : false}
              helperText={errors.orderNumberV?.message}
            />
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
            <Autocomplete
              disablePortal
              disableClearable
              // value={province || null}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                // setValue("salesChannelV", newValue.id);
              }}
              id="provinceId"
              options={salesChannel.map((option, index) => ({
                id: option.salesChannelId,
                label: option.name,
              }))}
              renderInput={(params) => (
                <TextField
                  label="Satış Kanalı"
                  {...params}
                  {...register("salesChannelV")}
                  error={errors.salesChannelV ? true : false}
                  helperText={errors.salesChannelV?.message}
                />
              )}
            />
            {/* <Autocomplete
              disablePortal
              disableClearable
              value={salesChannel || ""}
              onInputChange={(event, newInputValue) => {
                setSalesChannel(newInputValue);
              }}
              id="salesChannel"
              options={satisKanali}
              isOptionEqualToValue={(option, value) => option === value} // ignore whitespace
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Satış Kanalı"
                  {...register("salesChannelV")}
                  error={errors.salesChannelV ? true : false}
                  helperText={errors.salesChannelV?.message}
                />
              )}
            /> */}
          </div>

          <div style={{ width: "100%", marginRight: "20px" }}>
            <DatePickerMUI
              title={"Satış Tarihi"}
              value={sendingDate}
              setValue={setSendingDate}
            />
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
            <DatePickerMUI
              title={"Sevkiyat Tarihi"}
              value={sendingDispatchDate}
              setValue={setSendingDispatchDate}
            />
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="shipmentDeliveryDateInput">Süre</InputLabel>
              <Select
                labelId="shipmentDeliveryDateLabelId"
                id="shipmentDeliveryDateId"
                defaultValue={1}
                label="Sure"
                onChange={(e) => {
                  setDeliveryRangeSelected(e.target.value);
                }}
              >
                {deliveryRangeList.map((p, index) => (
                  <MenuItem key={index} value={p.id}>
                    {p.deliveryRangeName}
                  </MenuItem>
                ))}
              </Select>

              <p
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              ></p>
            </FormControl>
          </div>

          {/* <div style={{ width: "100%", marginRight: "20px" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setCarsId(newValue.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={carsList.map((option) => ({
                id: option.id,
                label: option.brand,
                numberPlate: option.numberPlate,
              }))}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.label}&nbsp;|&nbsp;{option.numberPlate}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("carV")}
                  error={errors.carV ? true : false}
                  helperText={errors.carV?.message}
                  label="Atanacak Araç"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div> */}
          <div style={{ width: "100%", marginRight: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="shipmentDeliveryDateInput">Durum</InputLabel>
              <Select
                labelId="shipmentDeliveryDateLabelId"
                id="shipmentDeliveryDateId"
                defaultValue={1}
                label="Sure"
                onChange={(e) => setDeliveryCatalogSelected(e.target.value)}
              >
                {shipmentStatuses.map((item, index) => {
                  if (index < 1) {
                    return (
                      <MenuItem key={index} value={item.shipmentStatuId}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>

              <p
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              ></p>
            </FormControl>
          </div>
          <div style={{ width: "100%", marginRight: "20px" }}>
          <FormControl fullWidth>
              <InputLabel id="shipmentDistributionWarehouseInput">Çıkış Deposu</InputLabel>
              <Select
                labelId="shipmentDistributionWarehouseLabelId"
                id="shipmentDistributionWarehouseId"
               // defaultValue={"5"}
               value={distributionWarehouseSelected} // Controlled Select
                label="CikisDeposu"
                onChange={(e) => {
                  setDistributionWarehouseSelected(e.target.value);
                }}
              >
                {distributionWarehouseList.map((p, index) => (
                  <MenuItem key={index} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>

              <p
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              ></p>
            </FormControl>

       {/*    <Autocomplete
              disablePortal
              disableClearable
              // value={province || null}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
             // onChange={(e) => {
              //  setOutletWarehouse(e.target.id);
            //  }} 
              
              onChange={(event) => {
                // setValue("outletWarehouseV", newValue.id);
                setOutletWarehouseId(event.target.value);
              //  setOutletPlace([{ id: newValue.id, type: newValue.type }]);
                console.log(outletWarehouseId);
              }}
              id="provinceId"
              options={outletWarehouse.map((option, index) => ({
                id: option.outletWarehouseId,
                label: option.name,
                type: option.type,
              }))}
              groupBy={(option) => {
                if (option.type === "Warehouse") return "Depo";
                else return "Mağaza";
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  key={`${option.id}-${option.label}`}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                   <LocalShippingIcon sx={{ height: "14" }} />
                  &nbsp;&nbsp;
                  {option.label}&nbsp;{option.lastName}&nbsp;
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Alıcı Depooo"
                  {...params}
                  {...register("outletWarehouseV")}
                  error={errors.outletWarehouseV ? true : false}
                  helperText={errors.outletWarehouseV?.message}
                />
              )}
            /> */}

         {/*  <Autocomplete
              disablePortal
              value={selectedReceiverObj || null}
              groupBy={(option) => {
                if (option.type === "Warehouse") return "Depo";
                else return "Mağaza";
              }}
              getOptionLabel={(option) =>
                option.name ? `${option.name} ` : ""
              }
              isOptionEqualToValue={(option, value) =>
                option.id === value.id ||
                (option.id === value.id && option.name === value.name)
              }
              onChange={(event, newValue) => {
                setSelectedReceiverObj(newValue);
                // setSenderId(newValue.id);
                // setSenderAddress(newValue.address);
                // setSenderName(newValue.label);
              }}
              id="combo-box-demo"
              options={senderDtoList.map((option, index) => ({
                id: option.id,
                label: option.name,
                address: option.address,
                name: option.name,
                lastName: option.lastName,
                type: option.type,
                tc: option.tc,
              }))}
              renderGroup={(params, index) => (
                <li key={`${params.key}-${params.group}-${index}-li`}>
                  {params.group}
                  <ul key={`${params.key}-${params.group}-ul`}>
                    {params.children}
                  </ul>
                </li>
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  key={`${option.id}-${option.type}-${option.label}`}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.type === "Customer" ? (
                    <PersonIcon sx={{ height: "14" }} />
                  ) : option.type === "Warehouse" ? (
                    <LocalShippingIcon sx={{ height: "14" }} />
                  ) : option.type === "Store" ? (
                    <StorefrontIcon sx={{ height: "14" }} />
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;
                  {option.label}&nbsp;{option.lastName}&nbsp;-&nbsp;
                  {option.tc}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("gatheringPlaceV")}
                  error={errors.gatheringPlaceV ? true : false}
                  helperText={errors.gatheringPlaceV?.message}
                  label="Alıcı Depo Seçiniz..."
                />
              )}
            /> */}


           {/*  <Autocomplete
              disableClearable
              disablePortal
              // value={province || null}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setOutletPlace([{ id: newValue.id, type: newValue.type }]);
              }}
              id="provinceId"
              options={senderDtoList.map((option) => ({
                id: option.id,
                label: option.name,
                type: option.type,
              }))}
              groupBy={(option) => {
                if (option.type === "Warehouse") return "Depo";
                else return "Mağaza";
              }}
              renderOption={(props, option, index) => (
                <Box
                  key={index}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.label}&nbsp;&nbsp;
                  {option.type === "Mağaza" ? (
                    <StorefrontIcon sx={{ height: "14" }} />
                  ) : option.type === "Depo" ? (
                    <LocalShippingIcon sx={{ height: "14" }} />
                  ) : (
                    ""
                  )}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Çıkış Yeri"
                  {...register("outletPlaceV")}
                  error={errors.outletPlaceV ? true : false}
                  helperText={errors.outletPlaceV?.message}
                />
              )}
            /> */}
          </div>
        </div>
      </Row>
      <Row>
        <MyDivBox title="Ürün Bilgileri" style={{ marginTop: "20px" }}>
          {/* <div style={{width:'150px'}}><QuickAddProduct setReflesh={setReflesh}/></div> */}
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid #ebedf2",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <p>Depodan ürün çıkacak mı?</p>

            <Switch
              {...switchLabel}
              checked={switchCheckProduct}
              color="secondary"
              onChange={() => {
                setSwitchCheckProduct(!switchCheckProduct);
              }}
            />
          </div>
          {switchCheckProduct && (
            <div
              style={{
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  marginBottom: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                <div>
                  {/*  <Button
                  onClick={addNewProductToList}
                  variant="contained"
                  style={{ backgroundColor: "#7b1fa2", borderRadius: "10px" }}
                >
                  Ürün Ekle
                </Button> */}

                  <GeneralButton
                    onClick={addNewProductToList}
                    title={"Ürün Ekle"}
                    color={"purple"}
                  ></GeneralButton>
                </div>
                {/*  <button
                  type="button"
                  onClick={addNewProductToList}
                  className="add-btn"
                >
                   Ürün Ekle 
                 
                  <AddCircleOutlineSharpIcon fontSize="small" />
                </button> */}
              </div>

              <div className="form-field" style={{ width: "100%" }}>
                {productList.map((p, index) => (
                  <div
                    key={index}
                    className="services"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      key={index + 1}
                      className="first-division"
                      style={{ width: "100%", marginRight: "20px" }}
                    >
                      <Autocomplete
                        disableClearable
                        options={filteredProduct}
                        noOptionsText={
                          "Ürün Stok Kodu... (Üstü çizili ürünler şu an stokta gözükmemektedir)"
                        }
                        getOptionLabel={(product) =>
                          `${product.productCode}  -  ${product.name}`
                        }
                        onChange={(e, newValue) => {
                          updateProductLabel(
                            "productCode",
                            index,
                            newValue.productCode
                          );
                          updateProductLabel("name", index, newValue.label);
                          updateProductLabel("id", index, newValue.id);
                        }}
                        onInputChange={(event) => {
                          const inputValue =
                            typeof event.target.value === "string"
                              ? event.target.value.trim()
                              : "";
                          if (event.target.value.length > 0) {
                            setTimeout(() => {
                              searchProducts(inputValue);
                            }, 1000);
                          } else {
                            setFilteredProduct([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...register(`products.${index}.name`)}
                            error={
                              errors.products?.[index]?.name ? true : false
                            }
                            helperText={errors.products?.[index]?.name?.message}
                            {...params}
                            variant="outlined"
                            label={index + 1 + ". Ürün"}
                          />
                        )}
                      />
                    </div>

                    <div style={{ width: "30%", marginRight: "20px" }}>
                      <TextField
                        style={{}}
                        name="quantity"
                        inputProps={{ min: "1", max: "50", step: "1" }}
                        value={p.quantity}
                        id="outlined-basic"
                        label="Adet"
                        type="Number"
                        variant="outlined"
                        onChange={(e) => updateProductQuantity(e, index)}
                      />
                    </div>

                    {/*    <div style={{ width: "10%", marginRight: "20px" }}>
                    {productList.length - 1 === index && (
                      <button
                        type="button"
                        onClick={addNewProductToList}
                        className="add-btn"
                      >
                        <span>
                          <AddCircleOutlineSharpIcon fontSize="small" />
                        </span>
                      </button>
                    )}
                  </div> */}
                    <div className="second-division">
                      {productList.length !== 1 && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeProductFromList(index)}
                          // aa(index)
                        >
                          <DeleteIcon />
                        </IconButton>
                        /*  <Button
                        onClick={() => removeProductFromList(index)}
                        className="add-btn"
                        style={{
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "red",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            marginRight: "2px",
                            marginLeft: "2px",
                          }}
                        >
                          {" "}
                          Kaldır{" "}
                        </span>
                      </Button> */

                        /*  <button
                        type="button"
                        onClick={() => removeProductFromList(index)}
                        className="remove-btn"
                      >
                        <span>Kaldır</span>
                      </button> */
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </MyDivBox>
      </Row>
      {/* 2.part */}

      <MyDivBox title="Alıcı Bilgileri" style={{ marginTop: "20px" }}>
        <div
          style={{
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "center",
              marginBottom: "10px",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Alıcı/Kişi/Birim</div>
            <div>
              {" "}
              {/*  <GeneralButton
                title={"Hızlı Ekle"}
                color={"purple"}
                onClick={() => setCustomerQuickAdd(true)}
              ></GeneralButton> */}
              <Button
                variant="contained"
                style={{ backgroundColor: "#7b1fa2", borderRadius: "10px" }}
                onClick={() => setCustomerQuickAdd(true)}
              >
                Hızlı Ekle
              </Button>
            </div>

            {/*  <div>
              {" "}
              <span
                style={{ justifyContent: "end" }}
                onClick={() => setCustomerQuickAdd(true)}
              >
                <QuickAdd />
              </span>
            </div> */}
          </div>
          <Autocomplete
            disablePortal
            value={selectedReceiverObj || null}
            groupBy={(option) => {
              if (option.type === "Customer") return "Müşteri";
              else return "tanımsız";
            }}
            getOptionLabel={(option) =>
              option.name ? `${option.name}- ${option.tc}` : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.id === value.id || option.id === value.id
            }
            onChange={(event, newValue) => {
              setSelectedReceiverObj(newValue);
              // setSenderId(newValue.id);
              // setSenderAddress(newValue.address);
              // setSenderName(newValue.label);
            }}
            id="combo-box-demo"
            options={receiverDtoList.map((option, index) => ({
              id: option.id,
              label: option.name,
              address: option.address,
              name: option.nameSurname,
              type: option.type,
              tc: option.tc,
            }))}
            renderGroup={(params, index) => (
              <li key={`${params.key}-${params.group}-${index}-li`}>
                {params.group}
                <ul key={`${params.key}-${params.group}-ul`}>
                  {params.children}
                </ul>
              </li>
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                key={`${option.id}-${option.type}-${option.label}`}
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.type === "Customer" ? (
                  <PersonIcon sx={{ height: "14" }} />
                ) : option.type === "Warehouse" ? (
                  <LocalShippingIcon sx={{ height: "14" }} />
                ) : option.type === "Store" ? (
                  <StorefrontIcon sx={{ height: "14" }} />
                ) : (
                  ""
                )}
                &nbsp;&nbsp;
                {option.label}&nbsp;{option.name}&nbsp;-&nbsp;{option.tc}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                {...register("receiverV")}
                error={errors.receiverV ? true : false}
                helperText={errors.receiverV?.message}
                label="Seçiniz..."
              />
            )}
          />

          <Autocomplete
            disableClearable
            noOptionsText={"Sonuç Bulamadı"}
            value={
              selectedReceiverObj && selectedReceiverObj.address
                ? selectedReceiverObj.address
                : null
            }
            disablePortal
            id="combo-box"
            sx={{ marginTop: "20px" }}
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label="Lütfen önce alıcı seçiniz" />
            )}
          />
        </div>
      </MyDivBox>
      <div className="split" style={{ padding: "10px" }}></div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <TextField
          id="outlined-multiline-flexible"
          label="Sevkiyat Notları..."
          fullWidth={true}
          {...register("noteV")}
        />
      </div>
      <div className="split" style={{ padding: "10px" }}></div>
      {/* hızlı ekle kısımları */}
      <div id="section2">
        {customerQuickAdd ? (
          <div
            style={{
              border: "3px gray dotted",
              margin: "20px",
              padding: "30px",
              boxSizing: "border-box",
              display: "flex", // Use flexbox
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              overflow: "hidden", // Prevent overflow
            }}
            id="customerQuickAdd"
            name="test1"
            ref={customerQuickAddRef}
          >
            <div>
              <ReceiverQuickAdd
                setCustomerQuickAdd={setCustomerQuickAdd}
                setReflesh={setReflesh}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="split" style={{ padding: "10px" }}></div>

      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          marginBottom: "20px",
        }}
      >
        <p>Yolda Sevkiyata Ürün Eklenecek Mi?</p>
        <Switch
          {...switchLabel}
          checked={switchCheck}
          color="secondary"
          onChange={() => {
            setSwitchCheck(!switchCheck);
          }}
        />
      </div>

      {switchCheck && (
        <MyDivBox title="Ürün Bilgileri" style={{ marginTop: "20px" }}>
          {/* <div style={{width:'150px'}}><QuickAddProduct setReflesh={setReflesh}/></div> */}

          <div
            style={{
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                textAlign: "center",
                marginBottom: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div>
                <GeneralButton
                  title={"Ürün Ekle"}
                  color={"purple"}
                  onClick={addNewProductAdditionToList}
                ></GeneralButton>

                {/* <Button
                  variant="contained"
                  style={{ backgroundColor: "#7b1fa2", borderRadius: "10px" }}
                  onClick={addNewProductAdditionToList}
                >
                  Ürün Ekle
                </Button> */}

                {/* <Button
                  onClick={addNewProductToList}
                  className="add-btn"
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      marginRight: "2px",
                      marginLeft: "2px",
                    }}
                  >
                    {" "}
                    Ürün Ekle{" "}
                  </span>
                  <AddCircleOutlineSharpIcon fontSize="small" />
                </Button> */}
              </div>
            </div>

            <div className="form-field" style={{ width: "100%" }}>
              {productListAddition.map((p, index) => (
                <div
                  key={index}
                  className="services"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    key={index + 1}
                    className="first-division"
                    style={{ width: "100%", marginRight: "20px" }}
                  >
                    <Autocomplete
                      disableClearable
                      options={filteredProduct}
                      noOptionsText={
                        "Ürün Stok Kodu... (Üstü çizili ürünler şu an stokta gözükmemektedir)"
                      }
                      getOptionLabel={(product) =>
                        `${product.productCode}  -  ${product.name}`
                      }
                      onChange={(e, newValue) => {
                        updateProductAdditionLabel(
                          "productCode",
                          index,
                          newValue.productCode
                        );

                        updateProductAdditionLabel(
                          "name",
                          index,
                          newValue.label
                        );
                        updateProductAdditionLabel("id", index, newValue.id);
                      }}
                      onInputChange={(event) => {
                        const inputValue =
                          typeof event.target.value === "string"
                            ? event.target.value.trim()
                            : "";
                        if (event.target.value.length > 0) {
                          setTimeout(() => {
                            searchProducts(inputValue);
                          }, 1000);
                        } else {
                          setFilteredProduct([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={index + 1 + ". Ürün"}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <Autocomplete
                      disableClearable
                      key={index + 2}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      options={senderDtoList.map((option) => ({
                        id: option.id,
                        label: option.name,
                        type: option.type,
                      }))}
                      value={p.outletChannelName || ""}
                      disablePortal
                      onChange={(e, newValue) => {
                        updateProductAdditionLabel(
                          "outletChannelId",
                          index,
                          newValue.id
                        );
                        updateProductAdditionLabel(
                          "outletChannelType",
                          index,
                          newValue.type
                        );
                        updateProductAdditionLabel(
                          "outletChannelName",
                          index,
                          newValue.label
                        );
                      }}
                      groupBy={(option) => {
                        if (option.type === "Warehouse") return "Depo";
                        else return "Mağaza";
                      }}
                      renderOption={(props, option, index) => (
                        <Box
                          key={index}
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.label}&nbsp;&nbsp;
                          {option.type === "Mağaza" ? (
                            <StorefrontIcon sx={{ height: "14" }} />
                          ) : option.type === "Depo" ? (
                            <LocalShippingIcon sx={{ height: "14" }} />
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Çıkış Yeri "
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>

                  <div style={{ width: "30%", marginRight: "20px" }}>
                    <TextField
                      style={{}}
                      name="quantity"
                      inputProps={{ min: "1", max: "50", step: "1" }}
                      value={p.quantity}
                      id="outlined-basic"
                      label="Adet"
                      type="Number"
                      variant="outlined"
                      onChange={(e) => updateProductAdditionQuantity(e, index)}
                    />
                  </div>

                  {/* <div style={{ width: "10%", marginRight: "20px" }}>
                    {productListAddition.length - 1 === index && (
                      <button
                        type="button"
                        onClick={addNewProductAdditionToList}
                        className="add-btn"
                      >
                        <span>
                          <AddCircleOutlineSharpIcon fontSize="small" />
                        </span>
                      </button>
                    )}
                  </div> */}
                  <div className="second-division">
                    {productListAddition.length !== 1 && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeProductAdditionFromList(index)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      /*   <button
                        type="button"
                        onClick={() => removeProductAdditionFromList(index)}
                        className="remove-btn"
                      >
                        <span>Kaldır</span>
                      </button> */
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MyDivBox>
      )}

      {/* 4.part */}
      <Row>
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <GeneralButton
            title={"Sevkiyatı Ekle"}
            color={"purple"}
            onClick={handleSubmit(onSubmit)}
          ></GeneralButton>
          <GeneralButton
            title={"İptal"}
            color={"red"}
            onClick={() => navigate(-1)}
          ></GeneralButton>
          {/*  <Button
            variant="contained"
            style={{
              backgroundColor: "#7b1fa2",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Sevkiyatı Ekle
          </Button>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            İptal
          </Button> */}
        </div>

        {/*  <div style={{ textAlign: "center", padding: "20px" }}>
          <Button style={{ margin: "10px" }} onClick={handleSubmit(onSubmit)}>
            Sevkiyatı Ekle
          </Button>

          <Button
            style={{ backgroundColor: "gray" }}
            onClick={() => navigate(-1)}
          >
            İptal
          </Button>
        </div> */}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ width: "500px" }}
        />
      </Row>
    </>
  );
}
