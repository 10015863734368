import authHeader from "../auth-header";
import config from "./config";
import { apiMessages } from "api/constant/messages";
const API_URL = config.basename + "Driver/";

async function getAll() {
  try {
    const responce = await fetch(API_URL + "getAll", authHeader("GET"));
    const serializeResponce = await responce.json();

    if (!responce.ok) {
      return [serializeResponce];
    } else {
      return [serializeResponce];
    }
  } catch (err) {
    console.log("driver service try yakaladı err", err);
  }
}
/* */

async function regionsAll() {
  try {
    const responce = await fetch(API_URL + "regionsAll", authHeader("GET"));

    if (!responce.ok) {
      return responce.ok;
    } else {
      const serializeResponce = await responce.json();

      return [serializeResponce];
    }
  } catch (err) {
    console.log("driver service try yakaladı err", err);
  }
}
/*--- */
const createRegionDriver = async (data) => {
  const user = localStorage.getItem("user");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,

      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      driverId: data.driverId,
      regionId: data.regionId,
    }),
  };
  try {
    const tokenFetch = await fetch(API_URL + "addRegionDriver", requestOptions);
    const responce = await tokenFetch.json();
    return responce;
  } catch (err) {
    console.log("createRegionDriver error! end point geçersiz olabilir!!!!");
    return false;
  }
};
/*--- */

async function getRegionDriver() {
  try {
    const responce = await fetch(
      API_URL + "getRegionDriver",
      authHeader("GET")
    );
    const serializeResponce = await responce.json();

    if (!responce.ok) {
      return [serializeResponce];
    } else {
      return [serializeResponce];
    }
  } catch (err) {
    console.log("driver service try yakaladı err", err);
  }
}

async function deleteDriverRegion(id) {
  const user = localStorage.getItem("user");

 const requestOptions = {
   withCredentials: true,
   method: "DELETE",
   headers: {
     Authorization: `Bearer ${user}`,
     Accept: "application/json",
     "Content-Type": "application/json;charset=UTF-8",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Credentials": "true",
   },
 };
 try {
   const tokenFetch = await fetch(API_URL + "deleteDriverRegion/" + id, requestOptions);
   const responce = await tokenFetch.json();
   return responce;
 } catch (err) {
   return {
     success: "error",
     message: apiMessages.deleteDriverRegion,
   };
 }
}


export const driverService = {
  getAll,
  regionsAll,
  createRegionDriver,
  getRegionDriver,
  deleteDriverRegion,
};
