import React, { useEffect } from "react";
import ReceiverForm from "./CustomerForm";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerQuickAdd({ setReflesh, setCustomerQuickAdd }) {
  const { refresh } = useSelector((state) => state.customer);

  // useEffect(() => {
  //   setCustomerQuickAdd(refresh);
  // }, [refresh]);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
      >
        <Tooltip title="Hızlı ekle penceresini kapat">
          <IconButton onClick={() => setCustomerQuickAdd(false)}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </div>
      <ReceiverForm setCustomerQuickAdd={setCustomerQuickAdd} />
    </div>
  );
}
