import {
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_FAIL,
  FETCH_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
} from "./types";
import { receiverSenderService } from "../../api/services/receiverSender.service";

export const fetchAllCustomer = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_CUSTOMER_REQUEST });
      return receiverSenderService.getAllCustomer().then((res) => {
        dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: res });
        return res;
      });
    } catch (error) {
      dispatch({ type: FETCH_CUSTOMER_FAIL, payload: error.message });
    }
  };
};

export const CreateCustomer = (customerData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_CUSTOMER_REQUEST });

      const createdCustomer = await receiverSenderService.createCustomer(
        customerData
      );

      dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: createdCustomer });

      return createdCustomer;
    } catch (error) {
      dispatch({ type: ADD_CUSTOMER_FAIL, payload: error.message });
    }
  };
};
export const UpdateCustomer = (customerData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CUSTOMER_REQUEST });

      const updatedCustomer = await receiverSenderService.updateCustomer(
        customerData
      );

      dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: updatedCustomer });

      return updatedCustomer; // Optional: Return the updated customer if needed
    } catch (error) {
      dispatch({ type: UPDATE_CUSTOMER_FAIL, payload: error.message });
    }
  };
};
