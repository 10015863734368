import {FETCH_PRODUCTS_FAIL,FETCH_PRODUCTS_REQUEST,FETCH_PRODUCTS_SUCCESS,ADD_PRODUCT_REQUEST
,ADD_PRODUCT_FAIL,ADD_PRODUCT_SUCCESS,DELETE_PRODUCT_REQUEST,DELETE_PRODUCT_SUCCESS
} from "../action/types";



const initialState = {
  products: [],
  loading: false,
  error: null,
  message:"",
};

  
function productsReducer(state = initialState, action) {
  switch (action.type) {
      /*fetch */
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading:true
      };
      case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading:false,
        
      };
      case FETCH_PRODUCTS_FAIL:
      return {
        ...state,
        loading:false,
        error:action.payload
      };
      /*CRUD */
     case ADD_PRODUCT_REQUEST:
        return {
          ...state,
          loading:true
        };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading:false,
        products:{name:"furkan"}
      };
     case ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading:false,
        error:action.payload

      };
     
            

    
    default:
      return state;
  }
}

  export  {productsReducer};
  