//console.log("aa",process.env.REACT_APP_API_REQUEST_URL)
const config = {
  basename: "https://xapi.doremusicxpress.com:6100/api/",
 // basename: process.env.REACT_APP_API_REQUEST_URL || "https://localhost:7272/api/",

};

export default config;
//deployment
//https://xapi.doremusicxpress.com:6100/api/
//https://xapi.doremusicxpress.com:6100/swagger/index.html

//local
//https://localhost:7272/api/
