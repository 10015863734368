import React from "react";
import { QRCodeSVG } from "qrcode.react";
import qricon from "./qricon.png";

export default function QrCode({ value }) {
  const API_URL = `https://www.doremusicxpress.com?KargomNerede=`;
  ////// const API_URL = "http://localhost:3000/shipments/orderNumber/Stest";
  // const API_URL = `http://localhost:3000/shipments/orderNumber/`;

 // console.log(value);

  return (
    <div style={{ margin: "20px" }}>
      <QRCodeSVG
        value={`${API_URL}${value}`}
        size={128}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"M"}
        includeMargin={false}
        imageSettings={{
          src: qricon,
          x: undefined,
          y: undefined,
          height: 32,
          width: 32,
          excavate: true,
        }}
      />
    </div>
  );
}
