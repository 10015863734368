import authHeader from "../auth-header";
import config from "./config";
const API_URL = config.basename + "DeliveryRange/";

async function getDeliveryRange() {
  try {
    const Responce = await fetch(API_URL + "getAll", authHeader("GET"));

    if (!Responce.ok) {
      return Responce.ok;
    } else {
      const result = await Responce.json();

      return [result];
    }
  } catch (err) {
    console.log("get getDeliveryRange try yakaladı err", err);
  }
}

export const deliveryService = {
  getDeliveryRange,
};
